import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import { apis } from '../../configuration/configurationAPI';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { Button, TextField, Snackbar, } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import MuiAlert from '@material-ui/lab/Alert';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import store from "../../utils/index";
import CircularProgress from '@mui/material/CircularProgress';

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});

const UnprofessionalBehaviorForm = () => {
  const { action, incident_id } = useParams();

  const editIncidentData = useSelector(commonSelectors.selectEditIncidentData);
  const viewIncidentData = useSelector(commonSelectors.selectViewIncidentData);
  const getCurrentTime = () => {
    const now = new Date();
    const utcOffset = -4 * 60; // UTC offset for Eastern Time Zone (-4 hours)
    const utcTime = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);
    const estTime = new Date(utcTime + (utcOffset * 60 * 1000));
    const hours = String(estTime.getHours()).padStart(2, '0');
    const minutes = String(estTime.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  const baererToken = useSelector(commonSelectors.selectBaererToken);
  const loginUserId = useSelector(commonSelectors.selectLoginUserId);
  const navigatateTo = useNavigate();
   const [processing, setProcessing] = useState(false);

  // general incident info
  const [generalincidenterror, setGeneralIncidenterror] = useState("");
  const [patientTypeError, setPatientTypeError] = useState("");
  const [injuryIncurredError, setInjuryIncurredError] = useState("");
  const [equipmentMalfunctionedError, setEquipmentMalfunctionedError] = useState("");

  // demographics info
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [genderError, SetGenderError] = useState("");
  const [streetError, setstreetError] = useState("");
  const [cityError, setcityError] = useState("");
  const [stateError, setstateError] = useState("");
  const [countryError, setcountryError] = useState("");


  // incident details
  const [incidentdateError, setIncidentdateError] = useState("");
  const [stateincidentoccuredError, setStateIncidentOccuredError] = useState("");
  const [siteincidentoccuredError, setSiteIncidentOccuredError] = useState("");

  // special additinal info
  const [departmentError, setDepartmentError] = useState("");
  const [hospitalizedOnError, setHospitalizedOnError] = useState("");
  const [incidentSeverityError, setIncidentSeverityError] = useState("");
  const [incidentdescriptionError, setIncidentdescriptionError] = useState("");

  //Successfully submitted popup
  const [showSuccessPopup, setShowSuccessPopup] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState('');


  const [inputValue, setInputValue] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const accessInfo = useSelector(commonSelectors.selectAccessInfo);
  const createAllowed = [ "1100", "1101", "1110", "1111"];

  // Check if the user has the permission to submit (create or update)
  const isAnonymousUser = JSON.parse(sessionStorage.getItem('rl6User'))?.isVisitor;
const canSubmit =isAnonymousUser || (accessInfo?.forms && createAllowed.includes(accessInfo.forms));
  
  // Determine if it's an "edit-only" permission (like 1010)
  const isEditOnly = accessInfo?.forms === "1010" || accessInfo?.forms === "1011";

// console.log("mmmmmmmmmnnnnnnnn",editIncidentData.spec);

  const [unprofessionalBehaviorState, setUnprofessionalBehaviorState] = useState(
    viewIncidentData.spec ? viewIncidentData.spec :
      (editIncidentData.spec ? editIncidentData.spec : {
        "general_incident_info": {
          "incident_type": "",
          "patient_type": "",
          "injury_incurred": "",
          "equipment_involved": ""
        },
        "demographics_info": {
          "first_name": "",
          "last_name": "",
          "gender": 0,
          "dob": null,
          "address": "",
          "street": "",
          "city": "",
          "state": "",
          "country": "",
          "zipcode": ""
        },
        "incident_details": {
          "incident_date": new Date(),
          "incident_time": "",
          "incident_state": "",
          "incident_site": "",
          "file_owner": "",
          "entered_by": "",
          "entered_date": new Date(),
          "entered_time": "",
          "witness_name": "",
          "witness_phone": "",
          "witness_address": ""
        },

        "special_additional_info": {
          "department_id": 0,
          // "incident_date": "",
          "hospitalized_on": "",
          "unprofessional_behavior_observed_type": "",
          "impact_of_unprofessional_behavior": "",
          "actions_taken_to_address_situation": "",
          "patient_reaction": "",
          "outcome": "",
          "sequelae": "",
          "relevant_history": "",
          "other_information": "",
          "physician_involved": false,
          "contributing_factors": "",
          "immediate_actions_taken": "",
          "reported_incident_severity": "",
          "incident_description": "",
          "attachment": []
        }
      })
  );

  const unprofessionalBehaviorStateString = JSON.stringify(unprofessionalBehaviorState);

  const handleStateChange = (key, value) => {
    let tempUnprofessionalBehaviorState = JSON.parse(JSON.stringify(unprofessionalBehaviorState));
    switch (key) {
      // general incident info
      case "incident_type": tempUnprofessionalBehaviorState.general_incident_info.incident_type = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "patient_type": tempUnprofessionalBehaviorState.general_incident_info.patient_type = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "injury_incurred": tempUnprofessionalBehaviorState.general_incident_info.injury_incurred = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "equipment_involved": tempUnprofessionalBehaviorState.general_incident_info.equipment_involved = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      // demographics
      case "first_name": tempUnprofessionalBehaviorState.demographics_info.first_name = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "last_name": tempUnprofessionalBehaviorState.demographics_info.last_name = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "gender": tempUnprofessionalBehaviorState.demographics_info.gender = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "dob": tempUnprofessionalBehaviorState.demographics_info.dob = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "address": tempUnprofessionalBehaviorState.demographics_info.address = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "street": tempUnprofessionalBehaviorState.demographics_info.street = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "city": tempUnprofessionalBehaviorState.demographics_info.city = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "state": tempUnprofessionalBehaviorState.demographics_info.state = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "country": tempUnprofessionalBehaviorState.demographics_info.country = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "zipcode": tempUnprofessionalBehaviorState.demographics_info.zipcode = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      // incident details
      case "incident_date": tempUnprofessionalBehaviorState.incident_details.incident_date = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "incident_time": tempUnprofessionalBehaviorState.incident_details.incident_time = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "incident_state": tempUnprofessionalBehaviorState.incident_details.incident_state = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "incident_site": tempUnprofessionalBehaviorState.incident_details.incident_site = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "file_owner": tempUnprofessionalBehaviorState.incident_details.file_owner = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "entered_by": tempUnprofessionalBehaviorState.incident_details.entered_by = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "entered_date": tempUnprofessionalBehaviorState.incident_details.entered_date = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "entered_time": tempUnprofessionalBehaviorState.incident_details.entered_time = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "witness_name": tempUnprofessionalBehaviorState.incident_details.witness_name = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "witness_phone": tempUnprofessionalBehaviorState.incident_details.witness_phone = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "witness_address": tempUnprofessionalBehaviorState.incident_details.witness_address = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      // special additional info


      case "department_id": tempUnprofessionalBehaviorState.special_additional_info.department_id = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "hospitalized_on": tempUnprofessionalBehaviorState.special_additional_info.hospitalized_on = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "unprofessional_behavior_observed_type": tempUnprofessionalBehaviorState.special_additional_info.unprofessional_behavior_observed_type = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "impact_of_unprofessional_behavior": tempUnprofessionalBehaviorState.special_additional_info.impact_of_unprofessional_behavior = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "actions_taken_to_address_situation": tempUnprofessionalBehaviorState.special_additional_info.actions_taken_to_address_situation = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "patient_reaction": tempUnprofessionalBehaviorState.special_additional_info.patient_reaction = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "outcome": tempUnprofessionalBehaviorState.special_additional_info.outcome = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "sequelae": tempUnprofessionalBehaviorState.special_additional_info.sequelae = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "relevant_history": tempUnprofessionalBehaviorState.special_additional_info.relevant_history = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;

      case "other_information": tempUnprofessionalBehaviorState.special_additional_info.other_information = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;

      case "physician_involved": tempUnprofessionalBehaviorState.special_additional_info.physician_involved = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "contributing_factors": tempUnprofessionalBehaviorState.special_additional_info.contributing_factors = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "immediate_actions_taken": tempUnprofessionalBehaviorState.special_additional_info.immediate_actions_taken = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "reported_incident_severity": tempUnprofessionalBehaviorState.special_additional_info.reported_incident_severity = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "incident_description": tempUnprofessionalBehaviorState.special_additional_info.incident_description = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;
      case "attachment": tempUnprofessionalBehaviorState.special_additional_info.attachment = value;
        setUnprofessionalBehaviorState(tempUnprofessionalBehaviorState);
        break;


      default: break;
    }
  }

  const formatDate = (date) => {
    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;

  };


  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = true;
    const namePattern = /^[a-zA-Z]+$/;
    const zipCodePattern = /^\d{6}$/;



    if (!unprofessionalBehaviorState.general_incident_info.incident_type) {
      setGeneralIncidenterror("Please Select a General Incident Type.");
    } else {
      setGeneralIncidenterror("");
    }
    if (!unprofessionalBehaviorState.general_incident_info.patient_type) {
      setPatientTypeError("Please Enter Patient Type.");
    } else {
      setPatientTypeError("");
    }
    if (!unprofessionalBehaviorState.general_incident_info.injury_incurred) {
      setInjuryIncurredError("Please Enter Injury Incurred.");
    } else {
      setInjuryIncurredError("");
    }
    if (!unprofessionalBehaviorState.general_incident_info.equipment_involved) {
      setEquipmentMalfunctionedError("Please Enter Equipment Involved/ Malfunctioned.");
    } else {
      setEquipmentMalfunctionedError("");
    }

    if (!unprofessionalBehaviorState.demographics_info.last_name.match(namePattern)) {
      setLastNameError("Last name should only contain alphabets.");
      valid = false;
    } else {
      setLastNameError("");
    }


    // incidentdetails
    if (!unprofessionalBehaviorState.incident_details.incident_date) {
      setIncidentdateError("Please Enter Incidentdate.");
    } else {
      setIncidentdateError("");
    }
    // if (!unprofessionalBehaviorState.incident_details.incident_state) {
    //   setStateIncidentOccuredError("Please Enter State Incident Occured.");
    // } else {
    //   setStateIncidentOccuredError("");
    // }

    // if (!unprofessionalBehaviorState.incident_details.incident_site) {
    //   setSiteIncidentOccuredError("Please Enter Site Incident Occured.");
    // } else {
    //   setSiteIncidentOccuredError("");
    // }

    // special additional info

    if (!unprofessionalBehaviorState.special_additional_info.department_id) {
      setDepartmentError("Select Department.");
    } else {
      setDepartmentError("");
    }
    if (!unprofessionalBehaviorState.special_additional_info.siteincidentoccuredError) {
     setSiteIncidentOccuredError("Select location where incident occurred.");
    } else {
     setSiteIncidentOccuredError("");
    }

    if (!unprofessionalBehaviorState.special_additional_info.reported_incident_severity) {
      setIncidentSeverityError("Please Select Incident Severity.");
    } else {
      setIncidentSeverityError("");
    }
    if (!unprofessionalBehaviorState.special_additional_info.incident_description) {
      setIncidentdescriptionError("Please Select  Incident Description.");
    } else {
      setIncidentdescriptionError("");
    }


    // Function to validate inputs (ensure all fields are filled)
    const validateInputs = () => {
      // Check for errors in form fields
      if (
        !unprofessionalBehaviorState.general_incident_info.incident_type ||
        !unprofessionalBehaviorState.general_incident_info.patient_type ||
        !unprofessionalBehaviorState.general_incident_info.injury_incurred ||
        !unprofessionalBehaviorState.general_incident_info.equipment_involved ||
        !unprofessionalBehaviorState.demographics_info.last_name ||
        !unprofessionalBehaviorState.incident_details.incident_date ||
        !unprofessionalBehaviorState.incident_details.incident_state ||
        !unprofessionalBehaviorState.incident_details.incident_site ||
        !unprofessionalBehaviorState.special_additional_info.department_id ||
        !unprofessionalBehaviorState.special_additional_info.reported_incident_severity ||
        !unprofessionalBehaviorState.special_additional_info.incident_description
      ) {
        return false;
      }
      return true;
    };

    if (!validateInputs()) {
      // console.log("Form submission failed. Please correct the errors.");
      return;
    }
    if (editIncidentData.incident_id) {
      handleUpdate();
    } else {
      handlePost();
    }
  };
  const putdata = {

    "file_count": 0,
    "is_deleted": false,
    "spec": unprofessionalBehaviorStateString,
    "updated_by": loginUserId,
    "updated_timestamp": new Date().toISOString('en-US'),
    "files_info": ""

  }
  const [files, setFiles] = useState([]);

  const handleFileUpload = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      setFiles([...files, ...selectedFiles]);
    }
  };
  const handlePost = () => {
    const formData = new FormData();
    var reqb = {
      is_deleted: false,
      spec: unprofessionalBehaviorStateString,
      created_by: loginUserId,
      // updated_by: loginUserId,
    }
    formData.append("IncidentInfo", JSON.stringify(reqb));


    files.forEach((file, index) => {
      formData.append(`files`, file);
    });
setProcessing(true);

    axios.post(apis.base + apis.POST_INCIDENT_API, formData, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        // console.log("Response data:", response.data);
        // console.log("Form submitted successfully!");
        setProcessing(false);
        setShowSuccessPopup(true);
        setSnackbarMessage("Form submitted successfully!");
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleUpdate = () => {
    axios.put(apis.base + apis.PUT_INCIDENT_BYID_API + editIncidentData.incident_id, putdata, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((res) => {
        // console.log("putdata", res);
        // console.log("Form updated successfully!");
        // resetFormState();
        setShowSuccessPopup(true);
        setSnackbarMessage('Feedback updated successfully!');
      })
      .catch((error) => {
        console.error("Error updating form:", error);
      });
  }
  const navigateTo = useNavigate();
  const handleCloseSnackbar = () => {
    setShowSuccessPopup(false); // Hide snackbar when closed
    navigateTo('/forms')
  };



  const generalIncidentTypeList = [
    { label: 'Unprofessional Behavior' },
    ,
  ]
  const patientTypeList = [
    { label: 'In-Patient' },
    { label: 'Out-Patient' },
  ]

  const reportedIncidentSeverityList = [
    { label: '1 - Minor' },
    { label: '2 - Moderate' },
    { label: '3 - Major' },
    { label: 'P3 - Potential Major' },
    { label: '4 - Catastrophic' },
    { label: 'P4-Potential Catastrophic' },
  ]
  const specificIncidentTypeList = [
    { label: 'Accidental puncture or laceration' },
    { label: 'Air embolism' },
    { label: 'Blood incompatibility' },
    { label: 'Catheter associated UTI' },
    { label: 'Central venous catheter related blood stream infection' },
    { label: 'Death among surgical inpatients' },
    { label: 'Falls and trauma' },
    { label: 'Foreign object retained after surgery' },
    { label: 'Iatrogenic pneumothorax' },
    { label: 'Iatrogenic pneumothorax with venous catheterization' },
  ]


  const typeofLinetube = [
    { label: 'Airway-Endotracheal Tube' },
    { label: 'Airway-Laryngeal Mask Airway' },
    { label: 'Airway-Nasal Airway' },
    { label: 'Airway-Oral Airway' },
    { label: 'Airway-Tracheostomy Airway' },
    { label: 'catheter-IV' },
    { label: 'catheter-Urinary Urethral' },
  ]
  const typeofRestraints = [
    { label: 'Vest' },
    { label: 'Siderailsx4' },
    { label: 'Siderailsx3' },
    { label: 'Siderailsx2' },
    { label: 'Posey Jacket' },
    { label: 'Other' },
    { label: 'None' },
    { label: 'Laptray' },
    { label: 'Lapbelt' },
  ]

  const reactionForPatient = [
    { label: 'Allergic reaction' },
    { label: 'Local reaction' },
    { label: 'Systemic reaction' },
    { label: 'Neurological disorders' },
  ]
  const sequelaeList = [
    { label: 'Medically significant events' },
    { label: 'Persistent disability' },
    { label: 'Birth defect' },
    { label: 'Not Applicable' },
  ]

  const [departmentData, setDepartmentData] = useState([]);
  const [locationData, setLocationData] = useState([])
  useEffect(() => {
    axios.get(apis.base + apis.GET_ALL_DEPARTMENT_API, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((response) => {
        setDepartmentData(response.data);
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });


    axios.get(apis.base + apis.GET_LOCATION_API, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((response) => {
        setLocationData(response.data);
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });
      axios.get(apis.base + apis.GET_INCIDENT_BYID_API + incident_id, {
        headers: {
          userid: loginUserId,
          Authorization: "Bearer " + baererToken
        }
      })
        .then((response) => {
         if (incident_id) {
            setUnprofessionalBehaviorState(JSON.parse(response.data.form_info.spec));
          }
        })
        .catch((errorresp) => {
          console.log(JSON.stringify(errorresp));
        });
  
  }, []);

  const handleDeleteFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };


// First name , Last name , File Owner name (first letter should be capitalized )
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
  return (
    <div>
      <div className="page-content">
        <div className="page-inner">
          <div className="page-title">
            <h3>Unprofessional Behavior Form </h3>
            <div className="page-breadcrumb">
              <ol className="breadcrumb">
                <li><Link to="/Dashboard">Home</Link></li>
                <li><Link to="/forms">Forms</Link></li>
                <li className="active"> Unprofessional Behavior Form</li>
              </ol>
            </div>
          </div>

          <div class="col-md-12 ">
            <div class="col-md-12">
              <form onSubmit={handleSubmit}>
              <div id="main-wrapper">
                  <div class="row ">
                    <div >
                      <div class="panel panel-white">
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >General Incident Information</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12"  >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6"  >
                                    <label for="surgeryType"   >General Incident Type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          options={generalIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          disabled={action == "view"}
                                          onChange={(event, value) => handleStateChange("incident_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select incident Type"
                                              fullWidth
                                            />
                                          )}
                                          value={generalIncidentTypeList.find(option => option.label === unprofessionalBehaviorState.general_incident_info.incident_type) || null}
                                        />) : (
                                        <Autocomplete
                                          disablePortal
                                          options={generalIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("incident_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select incident Type"
                                              fullWidth
                                            />
                                          )}
                                        />
                                      )}




                                      <div className="text-danger ">{generalincidenterror}</div>
                                    </div>

                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" >Patient Type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>

                                    <div >
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          options={patientTypeList}
                                          getOptionLabel={(option) => option.label}
                                          disabled={action == "view"}
                                          onChange={(event, value) => handleStateChange("patient_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select patient Type"
                                              fullWidth
                                            />
                                          )}
                                          value={patientTypeList.find(option => option.label === unprofessionalBehaviorState.general_incident_info.patient_type) || null}
                                        />
                                      ) : (
                                        <Autocomplete
                                          disablePortal
                                          options={patientTypeList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("patient_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select patient Type"
                                              fullWidth
                                            />
                                          )}
                                        />
                                      )}
                                      <div className="text-danger ">{patientTypeError}</div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" >Injury Incurred <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      <TextField type="text"
                                        fullWidth
                                        placeholder="Injury Incurred"
                                        disabled={action == "view"}
                                        value={unprofessionalBehaviorState.general_incident_info.injury_incurred}
                                        onChange={(e) => {
                                          var temp = JSON.parse(JSON.stringify(unprofessionalBehaviorState))
                                          temp.general_incident_info.injury_incurred = e.target.value;
                                          setUnprofessionalBehaviorState(temp)
                                        }}
                                      />
                                      <div className="text-danger">{injuryIncurredError}</div>
                                    </div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate" >Equipment Involved/ Malfunctioned <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      <TextField type="text"
                                        fullWidth
                                        placeholder="Equipment Involved/ Malfunctioned"
                                        disabled={action == "view"}
                                        value={unprofessionalBehaviorState.general_incident_info.equipment_involved}
                                        onChange={(e) => {
                                          const updatedState = {
                                            ...unprofessionalBehaviorState,
                                            general_incident_info: {
                                              ...unprofessionalBehaviorState.general_incident_info,
                                              equipment_involved: e.target.value
                                            }
                                          };
                                          setUnprofessionalBehaviorState(updatedState);
                                        }}
                                      />
                                      <div className="text-danger">{equipmentMalfunctionedError}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>


                <div id="main-wrapper">
                  <div class="row">
                    <  div>
                      <div class="panel panel-white">
                        <Accordion >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >Demographics</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12"  >

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6"  >
                                    <label  >First Name  </label>
                                    <div>
                                      <TextField type="text" fullWidth
                                        placeholder="First Name"

                                        disabled={action == "view"}

                                        value={unprofessionalBehaviorState.demographics_info.first_name}
                                        onChange={(e) => handleStateChange("first_name", capitalizeFirstLetter(e.target.value))}
                                      />

                                      <div className="text-danger">{firstNameError}</div>
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label >Last Name<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      <TextField type="text" fullWidth
                                        placeholder="Last Name"

                                        disabled={action == "view"}

                                        value={unprofessionalBehaviorState?.demographics_info?.last_name}
                                        onChange={(e) => handleStateChange("last_name", capitalizeFirstLetter(e.target.value))}
                                      />

                                      <div className="text-danger">{lastNameError}</div>
                                    </div>
                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div className="form-group col-md-6">
                                    <label htmlFor="exampleInputName2">Sex</label>
                                    <div>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={unprofessionalBehaviorState?.demographics_info?.gender === "male"} onChange={() => handleStateChange("gender", "male")} /> Male
                                      </label>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={unprofessionalBehaviorState?.demographics_info?.gender === "female"} onChange={() => handleStateChange("gender", "female")} /> Female
                                      </label>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={unprofessionalBehaviorState?.demographics_info?.gender === "others"} onChange={() => handleStateChange("gender", "others")} /> Others
                                      </label>
                                      <div className="text-danger col-md-6 ">{genderError}</div>
                                    </div>

                                  </div>

                                  < div class="form-group col-md-6 " style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="exampleInputName">DOB
                                      {/* <span style={{ fontweight: "bold", color: "red" }} >*</span> */}
                                    </label>
                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={unprofessionalBehaviorState?.demographics_info?.dob}
                                      onChange={(date) => {
                                        setUnprofessionalBehaviorState({
                                          ...unprofessionalBehaviorState,
                                          demographics_info: {
                                            ...unprofessionalBehaviorState?.demographics_info,
                                            dob: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />
                                    {/* <div className="text-danger">{dobError}</div> */}

                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div className=" form-group col-md-6" style={{ position: "relative" }}>
                                    <label >Address</label>
                                    {action === "view" ? (
                                      <div>
                                        <GooglePlacesAutocomplete
                                          apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                          selectProps={{
                                            onChange: (e, a, b) => {
                                              handleStateChange("address", e.value)
                                              geocodeByPlaceId(e.value.place_id)
                                                .then(results => {
                                                  // console.log(results)
                                                  handleStateChange("addressValues", {
                                                    "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                                    "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                                    "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                                    "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                                    "street": results[0].address_components.find(component => component.types.includes("route")).long_name,
                                                  })
                                                })
                                                .catch(error => console.error(error));
                                            }

                                          }}
                                        />
                                        <div style={{ position: "absolute", width: "95%", height: "37px", background: "black", opacity: "0.1", zIndex: 2, top: "24px" }}>
                                        </div>
                                      </div>
                                    ) : (
                                      <GooglePlacesAutocomplete
                                        apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                        selectProps={{
                                          onChange: (e, a, b) => {
                                            handleStateChange("address", e.value)
                                            geocodeByPlaceId(e.value.place_id)
                                              .then(results => {
                                                // console.log(results)
                                                handleStateChange("addressValues", {
                                                  "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                                  "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                                  "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                                  "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                                  "street": results[0].address_components.find(component => component.types.includes("route")).long_name,
                                                })
                                              })
                                              .catch(error => console.error(error));
                                          }

                                        }}
                                      />
                                    )}
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label >Street</label>
                                    <div>
                                      <TextField type="text" fullWidth placeholder="Street"
                                        value={unprofessionalBehaviorState?.demographics_info?.street}
                                        onChange={(e) => handleStateChange("street", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                      <div className="text-danger">{streetError}</div>
                                    </div>
                                  </div>

                                </div>


                                <div style={{ display: 'flex', gap:'40px',width:'100%' }}>
                                  <div class="form-group col-md-6">
                                    <label for="exampleInputName">City</label>
                                    <TextField type="text" fullWidth placeholder="City"
                                      value={unprofessionalBehaviorState?.demographics_info?.city}
                                      onChange={(e) => handleStateChange("city", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                    <div className="text-danger">{cityError}</div>
                                  </div>


                                  <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">State/Region</label>
                                    <TextField type="text" fullWidth placeholder="State/Region"
                                      value={unprofessionalBehaviorState?.demographics_info?.state}
                                      onChange={(e) => handleStateChange("state", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                    <div className="text-danger">{stateError}</div>
                                  </div>
                                  <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">Zip code</label>
                                    <TextField
                                      placeholder='Zip Code'
                                      fullWidth
                                      type="text"
                                      disabled={action == "view"}
                                      value={unprofessionalBehaviorState?.demographics_info?.zipcode}
                                      onChange={(event) => {
                                        const value = event.target.value;
                                        if (/^\d{0,5}$/.test(value)) {
                                          setInputValue(value);
                                          handleStateChange("zipcode", value);
                                        }
                                      }}
                                      inputProps={{
                                        pattern: '[0-9]*',
                                      }}
                                    />
                                  </div>
                                </div>



                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  {/* <div class="form-group col-md-6">
                                    <label for="exampleInputName">Country</label>
                                    <TextField type="text" fullWidth placeholder="Country"
                                      value={unprofessionalBehaviorState?.demographics_info?.country}
                                      onChange={(e) => handleStateChange("country", e.target.value)}
                                      disabled={action == "view"}

                                    />
                                    <div className="text-danger">{countryError}</div>
                                  </div> */}
                                  {/* <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">Zip code</label>
                                    <TextField
                                      placeholder='Zip Code'
                                      fullWidth
                                      type="text"
                                      disabled={action == "view"}
                                      value={unprofessionalBehaviorState?.demographics_info?.zipcode}
                                      onChange={(event) => {
                                        const value = event.target.value;
                                        if (/^\d{0,5}$/.test(value)) {
                                          setInputValue(value);
                                          handleStateChange("zipcode", value);
                                        }
                                      }}
                                      inputProps={{
                                        pattern: '[0-9]*',
                                      }}
                                    />
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>


                <div id="main-wrapper">
                  <div class="row">
                    <div>
                      <div class="panel panel-white">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >Incident  Details</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="surgeryType"  >Incident Date <span style={{ fontweight: "bold", color: "red" }} >*</span></label>

                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={unprofessionalBehaviorState?.incident_details?.incident_date}
                                      onChange={(date) => {
                                        setUnprofessionalBehaviorState({
                                          ...unprofessionalBehaviorState,
                                          incident_details: {
                                            ...unprofessionalBehaviorState?.incident_details,
                                            incident_date: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />


                                    <div className="text-danger">{incidentdateError}</div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" > Incident Time</label>
                                    <div>
                                      <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={action == "view"}
                                            type="time"
                                            defaultValue={getCurrentTime()}
                                            onChange={(e) =>
                                              handleStateChange(
                                                "incident_time",
                                                e.target.value
                                              )
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            InputProps={{
                                              inputProps: {
                                                max: getCurrentTime(),
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >State Where Incident Occurred at</label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.state}

                                          disabled={action == "view"}

                                          onChange={(e, value) => {
                                            handleStateChange("incident_state", value ? value.state : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select State Where Incident Occured"
                                              variant="outlined" />
                                          )}
                                          // defaultValue={{ label: unprofessionalBehaviorState.incident_details.incident_state }}
                                          value={locationData.filter(location => {
                                            return location.location_state == unprofessionalBehaviorState.incident_details.location_state
                                          })[0] || null}
                                          defaultValue={
                                            locationData.filter(location => {
                                              return location.location_state == unprofessionalBehaviorState.incident_details.location_state
                                            })[0] || {}
                                          }
                                        />) : (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.state}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_state", value ? value.state : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select State Where Incident Occured"
                                              variant="outlined" />

                                          )}
                                        />
                                      )}
                                      <div className="text-danger">{stateincidentoccuredError}</div>
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Location Where Incident Occured 
                                      <span style={{ fontweight: "bold", color: "red" }} >*</span>
                                      </label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.location_name}
                                          disabled={action == "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_site", value ? value.location_name : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Site Where Incident Occured"
                                              variant="outlined" />
                                          )}

                                          value={locationData.filter(locationsite => {
                                            return locationsite.location?.location_name == unprofessionalBehaviorState.incident_details.location?.location_name
                                          })[0] || null}
                                          defaultValue={
                                            locationData.filter(locationsite => {
                                              return locationsite.location?.location_name == unprofessionalBehaviorState.incident_details.location?.location_name
                                            })[0] || {}
                                          }
                                        />) : (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.location_name}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_site", value ? value.location_id : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Site Where Incident Occured"
                                              variant="outlined" x />
                                          )}
                                        />
                                      )}

                                      <div className="text-danger">{siteincidentoccuredError}</div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >File Owner </label>
                                    <div>
                                      <TextField type="text" placeholder='File Owner' fullWidth
                                        value={unprofessionalBehaviorState?.incident_details?.file_owner}
                                        onChange={(e) => handleStateChange("file_owner", capitalizeFirstLetter(e.target.value))}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Entered By</label>
                                    <div>
                                      <TextField type="text" placeholder='Entered By' fullWidth
                                        value={unprofessionalBehaviorState?.incident_details?.entered_by}
                                        onChange={(e) => handleStateChange("entered_by", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="procedureDate"  >Entered Date</label>

                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={unprofessionalBehaviorState?.incident_details?.entered_date}
                                      onChange={(date) => {
                                        setUnprofessionalBehaviorState({
                                          ...unprofessionalBehaviorState,
                                          incident_details: {
                                            ...unprofessionalBehaviorState.incident_details,
                                            entered_date: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />

                                  </div>


                                  <div class="form-group col-md-6">
                                    <label for="procedureDate">
                                      Entered Time (Estern Time Zone)
                                    </label>
                                    <div>
                                      <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={action == "view"}
                                            type="time"
                                            defaultValue={getCurrentTime()}
                                            onChange={(e) =>
                                              handleStateChange(
                                                "entered_time",
                                                e.target.value
                                              )
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            InputProps={{
                                              inputProps: {
                                                max: getCurrentTime(),
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Witness Name</label>
                                    <div>
                                      <TextField type="text" placeholder='Witness Name' fullWidth
                                        value={unprofessionalBehaviorState?.incident_details?.witness_name}
                                        onChange={(e) => handleStateChange("witness_name", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>


                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Witness Phone</label>
                                    <div>

                                      <TextField
                                        placeholder='Witness Phone'
                                        fullWidth
                                        disabled={action == "view"}
                                        type="text"
                                        value={unprofessionalBehaviorState?.incident_details?.witness_phone}
                                        onChange={(event) => {
                                          const value = event.target.value;
                                          if (/^\d{0,10}$/.test(value)) {
                                            setPhoneNumber(value);
                                            handleStateChange("witness_phone", value);
                                          }
                                        }}
                                        inputProps={{
                                          pattern: '[0-9]*',
                                        }}
                                      />

                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label for="procedureDate" style={{ marginLeft: '15px' }}>Witness Address </label>
                                  <div
                                    class="col-md-12"
                                  >
                                    <TextField type="text" placeholder='Witness Address' fullWidth
                                      value={unprofessionalBehaviorState?.incident_details?.witness_address}
                                      onChange={(e) => handleStateChange("witness_address", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Section-4 Special Incident Information */}

                <div id="main-wrapper">
                  <div class="row">
                    <div
                    // class="col-md-11" 
                    // style={{ paddingLeft: "90px", paddingRight: "0px" }}
                    >
                      <div class="panel panel-white">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header">
                            <h4 class="panel-title">Special Incident Information</h4>
                          </AccordionSummary>
                          <AccordionDetails>

                            <div class="panel-body">
                            <div class="form-group col-md-6">
                                    <label for="exampleInputPassword1"   >Department<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      {action === "view" || action === "edit" ? (

                                        <Autocomplete
                                          disabled={action == "view"}
                                          options={departmentData}
                                          getOptionLabel={(department) => department.department_name}
                                          onChange={(e, value) => {
                                            handleStateChange("department_id", value ? value.department_id : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select department"
                                              variant="outlined" x />
                                          )}
                                          value={departmentData.filter(department => {
                                            return department.department_id == unprofessionalBehaviorState.special_additional_info.department_id
                                          })[0] || null}
                                          defaultValue={
                                            departmentData.filter(department => {
                                              return department.department_id == unprofessionalBehaviorState.special_additional_info.department_id
                                            })[0] || {}
                                          }
                                        />
                                      ) : (
                                        <Autocomplete
                                          options={departmentData}
                                          getOptionLabel={(department) => department.department_name}
                                          onChange={(e, value) => {
                                            handleStateChange("department_id", value ? value.department_id : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select department"
                                              variant="outlined"
                                            />
                                          )}
                                        />
                                      )}
                                    </div>
                                    <div className="text-danger">{departmentError}</div>
                                  </div>

                              <div class="form-group col-md-6">
                                <label for="procedureDate" >Specify the type of unprofessional behavior observed</label>
                                <div >
                                  <TextField type="text" fullWidth
                                    disabled={action == "view"}
                                    placeholder="e.g., harassment, discrimination, unethical conduct"
                                    value={unprofessionalBehaviorState?.special_additional_info?.unprofessional_behavior_observed_type}
                                    onChange={(e) => handleStateChange("unprofessional_behavior_observed_type", e.target.value)}

                                  />
                                </div>
                              </div>
                              <div class="form-group col-md-12 ">
                                <label for="procedureDate" >Describe any impact the unprofessional behavior</label>
                                <div class="">
                                  <TextField type="text"
                                    disabled={action == "view"} fullWidth placeholder='Enter Surgeon'
                                    value={unprofessionalBehaviorState?.special_additional_info?.impact_of_unprofessional_behavior}
                                    onChange={(e) => handleStateChange("impact_of_unprofessional_behavior", e.target.value)}
                                  />
                                </div>
                              </div>

                              <div class="form-group col-md-6">
                                <label for="procedureDate" >Describe any immediate actions taken to address the situation</label>
                                <div
                                // class="col-md-10"
                                >
                                  <TextField
                                    disabled={action == "view"}
                                    type="text"
                                    fullWidth
                                    placeholder="e.g., reporting to supervisor, HR, or other authorities"
                                    value={unprofessionalBehaviorState?.special_additional_info?.actions_taken_to_address_situation}

                                    onChange={(e) => handleStateChange("actions_taken_to_address_situation", e.target.value)}

                                  />
                                </div>
                              </div>

                              <div class="form-group col-md-6">
                                <label for="exampleInputPassword1"  >Reaction for Patient </label>
                                {action === "view" || action === "edit" ? (
                                <Autocomplete
                                  disablePortal
                                  disabled={action == "view"}
                                  options={reactionForPatient}
                                  getOptionLabel={(option) => option.label}
                                  onChange={(event, value) => handleStateChange("patient_reaction", value ? value.label : "")}
                                  renderInput={(params) => (<TextField fullWidth {...params}
                                    placeholder=' Please select patient reaction' />)}
                                    value={reactionForPatient.find(option => option.label === unprofessionalBehaviorState.special_additional_info.patient_reaction) || null}
                                />):( <Autocomplete
                                  disablePortal
                                  options={reactionForPatient}
                                  getOptionLabel={(option) => option.label}
                                  onChange={(event, value) => handleStateChange("patient_reaction", value ? value.label : "")}
                                  renderInput={(params) => (<TextField fullWidth {...params}
                                    placeholder=' Please select patient reaction' />)}
                                />)}
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="procedureDate" >Patient Outcome</label>
                                <div>
                                  <TextField
                                    type="text"
                                    disabled={action == "view"}
                                    fullWidth
                                    placeholder="Recovered On/Not yet recovered/Died On"
                                    value={unprofessionalBehaviorState?.special_additional_info?.outcome}
                                    onChange={(e) => handleStateChange("outcome", e.target.value)}

                                  />
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <label htmlFor="procedureDate" >Sequelae</label>
                                <div>
                                {action === "view" || action === "edit" ? (
                                  <Autocomplete
                                    disablePortal
                                    disabled={action == "view"}
                                    options={sequelaeList}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => handleStateChange("sequelae", value ? value.label : "")}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Please Select Sequelae"
                                        fullWidth
                                      />
                                    )}
                                    value={sequelaeList.find(option => option.label === unprofessionalBehaviorState.special_additional_info.sequelae) || null}

                                  />):( <Autocomplete
                                    disablePortal
                                    options={sequelaeList}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => handleStateChange("sequelae", value ? value.label : "")}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Please Select Sequelae"
                                        fullWidth
                                      />
                                    )}
                                  />)}


                                </div>
                              </div>



                              <div className="form-group col-md-6">
                                <label htmlFor="procedureDate" >Allergies or other relevant history</label>
                                <div>
                                  <TextField
                                    disabled={action == "view"}
                                    type="text"
                                    fullWidth
                                    placeholder="Including medical history, liver / kidney problems, smoking, alcohol use etc"
                                    value={unprofessionalBehaviorState?.special_additional_info?.relevant_history}
                                    onChange={(e) => handleStateChange("relevant_history", e.target.value)}

                                  />
                                </div>
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="procedureDate" >Other Information</label>
                                <div>
                                  <TextField
                                    disabled={action == "view"}
                                    type="text"
                                    fullWidth
                                    placeholder=" Other Information"
                                    onChange={(e) => handleStateChange("other_information", e.target.value)}
                                    value={unprofessionalBehaviorState?.special_additional_info?.other_information}


                                  />
                                </div>
                              </div>




                              <div class="form-group col-md-6">
                                <label for="procedureDate" >Was a physician directly involved in Incident</label>
                                <div style={{ marginTop: '10px' }}>

                                  <label className="col-md-3">
                                    <input disabled={action == "view"} type="radio" name="physician_involved" checked={unprofessionalBehaviorState.special_additional_info.physician_involved === true} onChange={() => handleStateChange("physician_involved", true)} /> Yes

                                  </label>
                                  <label className="col-md-3">
                                    <input disabled={action == "view"} type="radio" name="physician_involved" checked={unprofessionalBehaviorState.special_additional_info.physician_involved === false} onChange={() => handleStateChange("physician_involved", false)} /> No
                                  </label>
                                </div>
                              </div>


                              <div className="form-group col-md-6">
                                <label htmlFor="procedureDate" >Contributing Factors</label>
                                <div>
                                  <TextField
                                    disabled={action == "view"}
                                    type="text"
                                    fullWidth
                                    placeholder="Enter Contributing Factors"
                                    onChange={(e) => handleStateChange("contributing_factors", e.target.value)}
                                    value={unprofessionalBehaviorState?.special_additional_info?.contributing_factors}
                                  />

                                </div>
                              </div>




                              <div className="form-group col-md-6">
                                <label htmlFor="procedureDate" >Immediate Actions Taken</label>
                                <div>
                                  <TextField
                                    disabled={action == "view"}
                                    type="text"
                                    fullWidth
                                    placeholder="Enter immediate actions here..."
                                    onChange={(e) => handleStateChange("immediate_actions_taken", e.target.value)}
                                    value={unprofessionalBehaviorState?.special_additional_info?.immediate_actions_taken}


                                  />
                                </div>
                              </div>

                              <div class="form-group col-md-6" >
                                <label for="exampleInputPassword1" >Reported Incident Severity <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                <div >
                                {action === "view" || action === "edit" ? (
                                  <Autocomplete
                                    disablePortal
                                    disabled={action == "view"}
                                    options={reportedIncidentSeverityList}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Please Select reported incident severity"
                                        fullWidth

                                      />
                                    )}
                                    value={reportedIncidentSeverityList.find(option => option.label === unprofessionalBehaviorState.special_additional_info.reported_incident_severity) || null}


                                  />):(<Autocomplete
                                    disablePortal
                                    options={reportedIncidentSeverityList}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Please Select reported incident severity"
                                        fullWidth

                                      />
                                    )}
                                  />)}
                                </div>
                                <div className="text-danger">{incidentSeverityError}</div>
                              </div>




                              <div class="form-group col-md-6">
                                <label for="procedureDate" >Incident Description - use SBAR (Situation,Background, Assessment,
                                  Recommendation )<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                <div >
                                  <TextField type="text"
                                    disabled={action == "view"} fullWidth placeholder="Enter Incident Description..."
                                    onChange={(e) => handleStateChange("incident_description", e.target.value)}
                                    value={unprofessionalBehaviorState?.special_additional_info?.incident_description}

                                  />
                                  <div className="text-danger">{incidentdescriptionError}</div>
                                </div>
                              </div>


                              <div className="form-group col-md-6">
                                <label htmlFor="procedureDate" style={{ textAlign: "start" }}>File Upload</label>
                                <div>
                                  <input type="file" disabled={action == "view"} style={{ marginTop: "10px", color: 'transparent' }} multiple onChange={handleFileUpload} />
                                  {files.length > 0 ? (
                                    <ol>
                                      {files.map((file, index) => (
                                        <li disabled={action === "view"} key={index}>
                                          {file.name}
                                          <IconButton disabled={action === "view"} onClick={() => handleDeleteFile(index)} aria-label="delete">
                                            <DeleteIcon />
                                          </IconButton>
                                        </li>
                                      ))}
                                    </ol>
                                  ) : (
                                    <p>No files chosen</p>
                                  )}

                                </div>
                              </div>






                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>


                <div style={{ marginLeft: "45%" }}>
                {canSubmit && action !== "view" &&(
                  <Button
                  disabled={action === "view"}
                    variant="contained"
                    type="submit"
                    style={{ height: "35px", width: "auto", fontSize: "15px", background: "#22baa0" }}
                    onClick={handleSubmit}
                  >
                    {editIncidentData.incident_id ? "Update" : "Submit"}
                  </Button>
  )}
                </div>
              </form>
              <Snackbar
                open={showSuccessPopup}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <MuiAlert onClose={handleCloseSnackbar}
                  severity="success"
                  variant="filled"
                  sx={{ width: '100%' }}
                  style={{ width: '300px', backgroundColor: "whitesmoke", color: "green", height: '45px', fontSize: '12px' }}>
                  {snackbarMessage}
                </MuiAlert>
              </Snackbar>
              {processing && (
              <Box sx={{ position: "fixed", top: "35%", left: "45%", backgroundColor: "white", width: "20%", height: "auto", boxShadow: "2px 2px 5px gray", paddingTop: "10px", paddingBottom: "10px",paddingLeft:"7%" }}>
                <CircularProgress size={60} color="primary" />
                <Box sx={{ marginTop: 2 }}>Submitting...</Box>
              </Box>
              )}
            </div>
          </div>
        </div>
      </div >
    </div >
  );
}

export default UnprofessionalBehaviorForm;
