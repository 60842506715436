import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { apis } from "../../configuration/configurationAPI";
import Button from '@mui/material/Button';
import { DialogContentText } from "@mui/material";
import { routers } from "../../configuration/configurationUI";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import DeparmentExportExcel from "../../Components/DepartmentExportButtons/DepartmentExportExcel";
import DepartmentExportPDF from "../../Components/DepartmentExportButtons/DepartmentExportPDF";
function DepartmentList() {
    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);
    const accessInfo = useSelector(commonSelectors.selectAccessInfo);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [data, setData] = React.useState({
        modalState: false,
        departmentIdToDelete: null,
        loading: false,
        success: false,
        error: null
    });
    const Navigate = useNavigate();

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });
    const [department, setDepartment] = useState([])
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [departmentIdToDelete, setDepartmentIdToDelete] = useState(null);
    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setData({ ...data, loading: true });
        axios.get(apis.base + apis.GET_ALL_DEPARTMENT_API, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken
            }
        })
            .then((response) => {
                setDepartment(response.data);
                setData({ ...data, loading: false });
            })
            .catch((errorresp) => {
                console.log(JSON.stringify(errorresp));
                setData({ ...data, loading: false, error: "Failed to fetch data" });
            });
    };

    const confirm = (departmentId) => {
        setDepartmentIdToDelete(departmentId);
        setDeleteDialogOpen(true);
    };

    const closeModal = () => {
        setDeleteDialogOpen(false);
    };
    const removeDepartment = () => {
        setData({ ...data, loading: true });
        axios.delete(apis.base + apis.DELETE_DEPARTMENT_API + `${departmentIdToDelete}?items_per_page=10&page_number=1`, {
            headers: {    
                userid: loginUserId,
                Authorization: "Bearer " + baererToken
            }
        })
            .then((succResp) => {
                setDepartment(department.filter(department => department?.department_id !== departmentIdToDelete));
                setDeleteDialogOpen(false);
            })
            .catch((errorresp) => {
                console.log(JSON.stringify(errorresp));
                setData({ ...data, loading: false, error: "Failed to delete department" });
            });
    };
    const deleteAllowed = ["1001", "1011", "1101", "1111"];
    const editAllowed = ["1010", "1011", "1110", "1111"];
    const canDelete = accessInfo?.departments && deleteAllowed.includes(accessInfo?.departments);
    const canEdit = accessInfo?.departments && editAllowed.includes(accessInfo?.departments);
    const columns = [
        { field: 'department_id', headerName: <strong>Department Id</strong>, width: 200 },
        { field: 'department_name', headerName: <strong>Department name</strong>, width: 220 },
        { field: 'location_name', headerName: <strong>Site Name</strong>, width: 250 },
        {
            field: 'action', headerName: <strong> Action</strong>, width: 250,
            renderCell: (params) => (
                <div>

                    {canDelete || canEdit ? (
                        <div>
                            {canEdit && (
                                <Link to={`/departmentsEdit/${params.row.department_id}`}> <BorderColorIcon style={{ fontSize: '22px', marginRight: '10px', color: 'black' }} /></Link>
                            )}
                            {canDelete && (
                                <DeleteIcon style={{ fontSize: '22px', marginLeft: '10px' }} onClick={() => confirm(params.row.department_id)} />
                            )}
                        </div>
                    ) : null}
                </div>
            )
        },
    ];
    const [error, setError] = useState("");
    const [loading, setLoading] = useState("");
    const [rows, setRows] = useState("");
    const addDepartments = ["1100", "1101", "1110", "1111"]
    return (
        <div className="page-content">
            <div className="page-inner">
                <div className="panel panel-white">
                    <div className="page-title">
                        <h3>Department List</h3>
                        <div className="page-breadcrumb">
                            <ol className="breadcrumb">
                                <li><Link to="/Dashboard">Home</Link></li>
                                <li>Department List</li>
                            </ol>
                        </div>
                    </div>
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mailbox-content" style={{ overflow: 'auto' }}>
                                    {!error && (
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 10px 5px" }}>
                                            <div>
                                                {addDepartments.includes(accessInfo?.departments) && (
                                                    <Link to={routers.departmentsadd}> <Button variant="contained" >Add Department</Button></Link>
                                                )}
                                            </div>
                                            <div style={{ display: "flex", gap: "10px" }}>
                                                {accessInfo?.exports === "1" && (
                                                    <>
                                                        <DeparmentExportExcel />
                                                        <DepartmentExportPDF />
                                                    </>
                                                )}
                                            </div>

                                        </div>
                                    )}

                                    {error && <p style={{ marginLeft: "30%", fontSize: "15px", color: "red" }}>{error}</p>}
                                    {loading && <p style={{ textAlign: "center", fontSize: "15px" }}>Loading...</p>}
                                    {!loading && !error && (
                                        <DataGrid
                                            rows={department}
                                            columns={columns}
                                            pageSize={5}
                                            rowsPerPageOptions={[10, 20, 30]}
                                            loading={loading}
                                            classes={{
                                                overlayWrapper: 'customOverlayWrapper'
                                            }}
                                            sx={{
                                                "& .customOverlayWrapper ": {
                                                    height: "63px !important"
                                                },
                                                fontWeight: 'fontWeightLight',
                                                mx: 0.5,
                                                fontSize: 14, fontFamily: "sans-serif",
                                                boxShadow: 2
                                            }}
                                            components={{
                                                Toolbar: () => (
                                                    <GridToolbarQuickFilter
                                                        classes={{
                                                            toolbarQuickFilter: 'customtoolbarQuickFilter'
                                                        }}
                                                        sx={{
                                                            width: '35% !important',
                                                            position: 'absolute !important',
                                                            right: 300,
                                                            top: '-30px !important'
                                                        }}
                                                        filterModel={filterModel}
                                                        onFilterModelChange={(newModel) => setFilterModel(newModel)}
                                                    />
                                                ),
                                                NoRowsOverlay: () => (
                                                    <div
                                                        style={{
                                                            position: 'sticky',
                                                            top: '50%',
                                                            transform: 'translate(-50%, 0%)',
                                                            textAlign: 'center',
                                                            marginLeft: '45%'
                                                        }}
                                                    >
                                                        No data found
                                                    </div>
                                                ),

                                            }}
                                        />
                                    )}
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={closeModal}
                >
                    <DialogTitle>Delete Profile</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this profile?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{ backgroundColor: "#34425a", color: "white" }} onClick={closeModal}>Cancel</Button>
                        <Button style={{ backgroundColor: "#22baa0", color: "white", }} onClick={() => removeDepartment(data.departmentIdToDelete)} variant="contained" color="error">Delete</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}
export default DepartmentList;
