import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apis } from "../../configuration/configurationAPI";
import "react-datepicker/dist/react-datepicker.css";
import store from "../../utils/index";
import { useSelector } from 'react-redux';
import { commonActions, commonSelectors } from '../../utils/commonstore';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { FaEye, FaTrash, FaFile, FaDownload } from 'react-icons/fa';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button,TextField, MenuItem, Select, Checkbox, ListItemText, InputLabel, FormControl } from "@mui/material";
import { Alert, Snackbar, ClickAwayListener } from "@mui/material";
import { routers } from "../../configuration/configurationUI";
import IncidentExportExcel from "../../Components/IncidentExportButtons/IncidentExportExcel";
import IncidentExportPDF from "../../Components/IncidentExportButtons/IncidentExportPDF";
import SingleFileExportPDF from "../../Components/IncidentExportButtons/SinglePDF";
import SingleFileExportExcel from "../../Components/IncidentExportButtons/SingleExcel";


const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});

function SubmittedFiles() {
  const accessInfo = useSelector(commonSelectors.selectAccessInfo);
  const Navigate = useNavigate();
  const baererToken = useSelector(commonSelectors.selectBaererToken);
  const loginUserId = useSelector(commonSelectors.selectLoginUserId);
  const [data, setData] = useState([]);
  // for pdf 
  const [searchValue, setSearchValue] = useState('');  
  // console.log("searchValue:",searchValue)
  // for excel 
  // const[searchvalueexcel, setSearchValueExcel]= useState(''); 

  const [openDialogMail, setOpenDialogMail] = useState(false);
  const [incidentId, setIncidentId] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [rolesList, setRolesList] = useState([]);

  const handleDialogOpen = (incidentId) => {
    setIncidentId(incidentId);  
    setOpenDialogMail(true);
  };

  const handleDialogClose = () => {
    setOpenDialogMail(false);
    setSelectedRoles([]);  
  };

  const handleDialogSave = () => {
    sendMail();
    setOpenDialogMail(false);
    setSelectedRoles([]);
  };




  


  
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };
  var navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedIncidentFiles, setSelectedIncidentFiles] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedFileToDelete, setSelectedFileToDelete] = useState(null);
  const [selectedIncidentId, setSelectedIncidentId] = useState(null);

  const renderCreatedByCell = (params) => {
    return params.value ? params.value : 'Anonymous';
  };


  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    fetchData();
    fetchData2();
    fetchRoles();
  }, []);

  const handleRoleChange = (event) => {
    setSelectedRoles(event.target.value);
  };

  const fetchRoles = () => {
    axios.get(apis.base + apis.GET_ALL_ROLES_API,
      {
        headers: {
          userid: loginUserId,
          Authorization: "Bearer " + baererToken
        }
      })
      .then(response => {
        setRolesList(response.data);
        console.log("response.data",response.data)
        setLoading(false);
      })
      .catch(error => {
        setError("Error while fetching data. Please try again.");
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const fetchData = () => {
    axios.get(apis.base + apis.GET_INCIDENTS_ALL_API,
      {
        headers: {
          userid: loginUserId,
          Authorization: "Bearer " + baererToken
        }
      })
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError("Error while fetching data. Please try again.");
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const sendMail = () => {
    axios.get(apis.base + "/incident/sendmails/"+ incidentId,
      {
        headers: {
          userid: loginUserId,
          Authorization: "Bearer " + baererToken,
          roleId:JSON.stringify(selectedRoles)
        }
      })
      .then(response => {
        console.log("sendmail",response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error sending an email:", error);
      });
  };

  const handleEditClick = (rowData) => {
    dispatch(commonActions.setEditIncidentData(rowData))
    const eventType2 = rowData.spec.general_incident_info.incident_type;
    switch (eventType2) {
      case "Adverse Drug":
        navigate(routers.AdverseDrug + "/" + rowData.incident_id + "/edit");
        break;
      case "Airway Management":
        navigate(routers.AirwayManagement + "/" + rowData.incident_id + "/edit");
        break;
      case "Anesthesia or Sedation":
        navigate(routers.Anesthesia + "/" + rowData.incident_id + "/edit");
        break;
      case "Coding Complications":
        navigate(routers.CodingComplication + "/" + rowData.incident_id + "/edit");
        break;
      case "Diagnostic Image":
        navigate(routers.DiagnosticImaging + "/" + rowData.incident_id + "/edit");
        break;
      case "Fall":
        navigate(routers.Fall + "/" + rowData.incident_id + "/edit");
        break;
      case "Feedback":
        navigate(routers.Feedback + "/" + rowData.incident_id + "/edit");
        break;
      case "Blood And Blood Products":
        navigate(routers.BloodAndBloodProducts + "/" + rowData.incident_id + "/edit");
        break;
      case "Lab/Specimen Collection":
        navigate(routers.LabOrSpecimen + "/" + rowData.incident_id + "/edit");
        break;
      case "MedicalCare Treatment":
        navigate(routers.MedicalCareTreatment + "/" + rowData.incident_id + "/edit");
        break;
      case "Medical Device Reporting":
        navigate(routers.MedicalDeviceReporting + "/" + rowData.incident_id + "/edit");
        break;
      case "Medication/Chemotherpy":
        navigate(routers.MedicationOrChemotherpy + "/" + rowData.incident_id + "/edit");
        break;
      case "Obstetrics":
        navigate(routers.Obstetrics + "/" + rowData.incident_id + "/edit");
        break;
      case "Patient Behavior":
        navigate(routers.PatientBehaviour + "/" + rowData.incident_id + "/edit");
        break;

      case "Patient Privacy":
        navigate(routers.PatientPrivacy + "/" + rowData.incident_id + "/edit");
        break;
      case "Restraints":
        navigate(routers.Restraints + "/" + rowData.incident_id + "/edit");
        break;
      case "Safety/Security/Facility":
        navigate(routers.SafetySecurityFacility + "/" + rowData.incident_id + "/edit");
        break;
      case "Surgery Procedure Consent":
        navigate(routers.SurgeryProcedure + "/" + rowData.incident_id + "/edit");
        break;
      case "Unprofessional Behavior":
        navigate(routers.UnprofessionalBehaviour + "/" + rowData.incident_id + "/edit");
        break;
      default:
        break;
    }

  };

  const handleViewClick = (rowData) => {
     dispatch(commonActions.setViewIncidentData(rowData));
    const eventType = rowData.spec.general_incident_info.incident_type;
    switch (eventType) {
      case "Adverse Drug":
        navigate(routers.AdverseDrug + "/" + rowData.incident_id + "/view");
        break;
      case "Airway Management":
        navigate(routers.AirwayManagement + "/" + rowData.incident_id + "/view");
        break
      case "Anesthesia or Sedation":
        navigate(routers.Anesthesia + "/" + rowData.incident_id + "/view");
        break;
      case "Coding Complications":
        navigate(routers.CodingComplication + "/" + rowData.incident_id + "/view");
        break;
      case "Diagnostic Image":
        navigate(routers.DiagnosticImaging + "/" + rowData.incident_id + "/view");
        break;
      case "Fall":
        navigate(routers.Fall + "/" + rowData.incident_id + "/view");
        break;
      case "Feedback":
        navigate(routers.Feedback + "/" + rowData.incident_id + "/view");
        break;
      case "Blood And Blood Products":
        navigate(routers.BloodAndBloodProducts + "/" + rowData.incident_id + "/view");
        break;
      case "Lab/Specimen Collection":
        navigate(routers.LabOrSpecimen + "/" + rowData.incident_id + "/view");
        break;
      case "MedicalCare Treatment":
        navigate(routers.MedicalCareTreatment + "/" + rowData.incident_id + "/view");
        break;
      case "Medical Device Reporting":
        navigate(routers.MedicalDeviceReporting + "/" + rowData.incident_id + "/view");
        break;
      case "Medication/Chemotherpy":
        navigate(routers.MedicationOrChemotherpy + "/" + rowData.incident_id + "/view");
        break;
      case "Obstetrics":
        navigate(routers.Obstetrics + "/" + rowData.incident_id + "/view");
        break;
      case "Patient Behavior":
        navigate(routers.PatientBehaviour + "/" + rowData.incident_id + "/view");
        break;
      case "Patient Privacy":
        navigate(routers.PatientPrivacy + "/" + rowData.incident_idy + "/view");
        break;
      case "Restraints":
        navigate(routers.Restraints + "/" + rowData.incident_id + "/view");
        break;
      case "Safety/Security/Facility":
        navigate(routers.SafetySecurityFacility + "/" + rowData.incident_id + "/view");
        break;
      case "Surgery Procedure Consent":
        navigate(routers.SurgeryProcedure + "/" + rowData.incident_id + "/view");
        break;
      case "Unprofessional Behavior":
        navigate(routers.UnprofessionalBehaviour + "/" + rowData.incident_id + "/view");
        break;
      default:
        break;
    }
  };

  const handleFilesClick = (incidentId) => {
    fetchData2(incidentId);
    setSelectedIncidentId(incidentId);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const fetchData2 = (incidentId) => {

    axios.get(apis.base + apis.GET_INCIDENTS_ALL_API,

      {
        headers: {
          userid: loginUserId,
          Authorization: "Bearer " + baererToken
        }
      })
      .then((response) => {
        const filesInfo = JSON.parse(response.data.form_info?.files_info || "[]");
        setSelectedIncidentFiles(filesInfo);

      })
      .catch((error) => {
        console.error("Error fetching uploaded files: ", error);
      });
  };

  const handleOpenDeleteDialog = (file) => {
    setSelectedFileToDelete(file);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedFileToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleDeleteSingleFile = (fileName) => {
    const url = `${apis.base}${apis.DELETE_INCIDENT_INCIDENTID_FILENAME_API}?fileKeyword=${encodeURIComponent(fileName)}`;
    axios.delete(url, {
      responseType: 'blob',
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken,
        incident_id: selectedIncidentId
      }
    })
      .then((response) => {
        setSnackbar({
          open: true,
          message: 'Deleted successfully',
          severity: 'success',
        });

      })
      .catch((error) => {
        console.error("Error deleting file: ", error);
        setSnackbar({
          open: true,
          message: 'Delete Unsuccessful',
          severity: 'error',
        });
      });
    handleCloseDeleteDialog();
  };
  const handleDownloadSingleFile = (file) => {
    const fileName = file;
    axios.get(apis.base + apis.GET_INCIDENT_FILENAME_INCIDENTID_DOWNLOAD_API + `?fileKeyword=${encodeURIComponent(fileName)}`, {
      responseType: 'blob',
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken,
        incident_id: selectedIncidentId
      }
    })
      .then((response) => {
        const blob = new Blob([response.data]);
        const extension = fileName.split('.').pop();
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.setAttribute("download", fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setSnackbar({
          open: true,
          message: 'Downloaded successfully',
          severity: 'success',
        });
      })
      .catch((error) => {
        console.error("Error downloading file: ", error);
        setSnackbar({
          open: true,
          message: 'Download failed',
          severity: 'error',
        });
      });

  };


  const handleDownloadAllattachements = (incidentId) => {
    axios.get(apis.base + apis.GET_INCIDENT_INCIDENTID_FILEZIP_API, {
      headers: {
        userId: loginUserId,
        Authorization: "Bearer " + baererToken,

        incident_id: incidentId
      },
      responseType: 'blob'

    })
      .then((response) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' })); // Set content type to 'application/zip'
        downloadLink.setAttribute("download", "incident_files.zip");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setSnackbar({
          open: true,
          message: 'Downloaded successfully',
          severity: 'success',
        });
      })
      .catch((error) => {
        setSnackbar({
          open: true,
          message: 'Download failed',
          severity: 'error',
        });
        console.error("Error fetching zip file: ", error);
      });
  }



  const columns = [
    {
      field: 'incident_id',
      headerName: <strong>Incident ID</strong>,
      width: 120,
      renderCell: (params) => {
        const allowedWorkflows = ["1000", "1001", "1010", "1011", "1100", "1101", "1110", "1111"];
        const viewAllowed = ["1000", "1001", "1010", "1011", "1100", "1101", "1110", "1111"];
        const isLinkAllowed = allowedWorkflows.includes(accessInfo?.workflow);
        const canEdit = accessInfo?.submitted_files && viewAllowed.includes(accessInfo.submitted_files);

        return (
          <div>
            {canEdit ? (
          <div
            style={{ cursor: 'pointer', color: '#22BAA0' }}
            onClick={() => handleViewClick(params.row)} 
          >
            {params.row.incident_id}
          </div>
        ) : (
          <span>{params.row.incident_id}</span> 
        )}
     
           
           
          </div>
        );
      }
    },
    {
      field: 'incident_type', headerName: <strong>Incident Type</strong>, width: 220,
      valueGetter: (params) => {
        if (params.row.spec && params.row.spec.general_incident_info) {
          return params.row.spec.general_incident_info.incident_type || '';
        } else {
          return '';
        }
      }
    },
    {
      field: 'patient_type', headerName: <strong> Patient Type</strong>, width: 150,
      valueGetter: (params) => {
        if (params.row.spec && params.row.spec.general_incident_info) {
          return params.row.spec.general_incident_info.patient_type || '';
        } else {
          return '';
        }
      }
    },
    {
      field: 'department_name', headerName: <strong> Department</strong>, width: 150,
    },
    { field: 'created_by_user', headerName: <strong>Created By</strong>, width: 150, renderCell: renderCreatedByCell },
    {
      field: 'created_timestamp', headerName: <strong> Created Timestamp</strong>, width: 200,
      valueGetter: (params) => new Date(params.row.created_timestamp).toLocaleString('en-US')
    },
    { field: 'updated_by_user', headerName: <strong>Updated By</strong>, width: 150 },
    {
      field: 'updated_timestamp',
      headerName: <strong>Updated Timestamp</strong>,
      width: 200,
      valueGetter: (params) => {
        const timestamp = params.row.updated_timestamp;
        return timestamp ? new Date(timestamp).toLocaleString('en-US') : '';
      }
    },   
    // {
    //   field: 'file_count',
    //   headerName: <strong>Documents</strong>,
    //   width: 100,
    //   renderCell: (params) => (
    //     <div style={{ cursor: 'pointer' }}  onClick={() => handleFilesClick(params.row.incident_id)}>

    //     <FaFile style={{ color: 'black' }}  />

    //     </div>
    //   )
    // },
    {
      field: 'actions', headerName: <strong>Actions</strong>,width: 200,
      renderCell: (params) => {
        let incidentType = '';
        if (params.row.spec && params.row.spec.general_incident_info) {
          incidentType = params.row.spec.general_incident_info.incident_type || '';
        }
        const rowData = params.row;
        const rowData2 = params.row;

        const viewAllowed = ["1000", "1001", "1010", "1011", "1100", "1101", "1110", "1111"];
        const allowedWorkflows = ["1000", "1001", "1010", "1011", "1100", "1101", "1110", "1111"];
        const editAllowed = ["1010", "1011", "1110", "1111"];

        const isLinkAllowed = allowedWorkflows.includes(accessInfo?.workflow);
        const canEdit = accessInfo?.submitted_files && editAllowed.includes(accessInfo.submitted_files);



        return (

          <div>
          {isLinkAllowed || canEdit ? (
            <div>
              {isLinkAllowed && (
            <span
              style={{ marginRight: '10px', color: 'black', cursor: 'pointer' }}
              onClick={() => (window.location.href = `/workflowactions/${params.row.incident_id}`)} 
            >
              <FaEye style={{ color: 'black' }} />
            </span>
          )}
              {canEdit && (
                <span style={{ marginRight: '10px', color: 'black',cursor:"pointer" }}>
                  <BorderColorIcon onClick={() => handleEditClick(rowData)} />
                </span>
              )}
              <Button variant="contained" onClick={() =>handleDialogOpen(params.row.incident_id)}>Send Mail</Button>
            </div>
          ) : null}
        </div>
        );

      }
    },
  ];

  const sortedData = [...data].sort((a, b) => b.incident_id - a.incident_id);
  const handleSnackbarClose = () => {
    Navigate("/submittedfiles")
    setSnackbar({ ...snackbar, open: false });
  };
  return (
    <div>
      <div className="page-content">
        <div className="page-inner">
          <div className="panel panel-white">
            <div className="page-title">
              <h3>Submitted Forms</h3>
              <div className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li><Link to="/Dashboard">Home</Link></li>
                  <li>Submitted Forms</li>
                </ol>
              </div>
            </div>
            <ClickAwayListener onClickAway={() => { }}>
              <Snackbar
                open={snackbar.open}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                style={{ position: 'fixed', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}
              >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} style={{ width: '300px', height: '40px', fontSize: '12px' }}>
                  {snackbar.message}
                </Alert>
              </Snackbar>
            </ClickAwayListener>

            <div id="main-wrapper">
              <div className="row m-t-md">
                <div className="col-md-12">
                  <div className="mailbox-content" style={{ overflow: 'auto' }}>
                    {!error && (
                      <div>
                        <div style={{ display: "flex", position: "relative", top: "20px", gap: "10px", justifyContent: "end", paddingRight: "20px" }}>
                          {accessInfo?.exports === "1" && (
                            <>
                              <IncidentExportExcel />
                              <IncidentExportPDF />
                              {/* <SingleFileExportPDF search={searchValue}/>
                              <SingleFileExportExcel   search={searchValue}/> */}
                            </>
                          )}
                        </div>
                      </div>

                    )}
                    <div style={{ height: "100%", width: '100%', paddingTop: "20px" }}>
                      {error && <p style={{ marginLeft: "30%", fontSize: "15px", color: "red" }}>{error}</p>}
                      {loading && <p style={{ textAlign: "center", fontSize: "15px" }}>Loading...</p>}
                      {!loading && !error && (
                        <DataGrid
                          rows={sortedData}
                          columns={columns}
                          pageSize={5}
                          rowsPerPageOptions={[10, 20, 30]}
                          loading={loading}
                          classes={{
                            overlayWrapper: 'customOverlayWrapper'
                          }}
                          sx={{
                            "& .customOverlayWrapper ": {
                              height: "63px !important"
                            },
                            fontWeight: 'fontWeightLight',
                            mx: 0.5,
                            fontSize: 14, fontFamily: "sans-serif",
                            boxShadow: 2
                          }}
                          components={{
                            Toolbar: () => (
                              <GridToolbarQuickFilter
                                classes={{
                                  toolbarQuickFilter: 'customtoolbarQuickFilter'
                                }}
                                
                      
                                sx={{
                                  width: '35% !important',
                                  position: 'absolute !important',
                                  left: 50,  // Move to the left
                                  top: '-30px !important'
                                }}
                              />
                            ),
                            NoRowsOverlay: () => (
                              <div
                                style={{
                                  position: 'sticky',
                                  top: '50%',
                                  transform: 'translate(-50%, 0%)',
                                  textAlign: 'center',
                                  marginLeft: '45%'
                                }}
                              >
                                No data found
                              </div>
                            ),

                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle style={{ fontSize: "20px" }}>Available Files</DialogTitle>
              <DialogContent>
                {selectedIncidentFiles.length === 0 ? (
                  <p>No files available</p>
                ) : (
                  selectedIncidentFiles.map((file, index) => (
                    <div key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "black" }}>                            <p>{file}</p>
                      <div style={{ position: "relative", left: "40px" }}>
                        <Button onClick={() => handleDownloadSingleFile(file)}><FaDownload style={{ color: "black", fontSize: "13px" }} /></Button>
                        <Button onClick={() => handleOpenDeleteDialog(file)}><FaTrash style={{ color: "red", fontSize: "13px" }} /></Button>

                      </div>
                    </div>
                  ))
                )}
              </DialogContent>
              <DialogActions>
                {selectedIncidentFiles.length > 0 && (
                  <Button style={{ backgroundColor: "#22baa0", color: "white" }} onClick={() => handleDownloadAllattachements(selectedIncidentId)} color="primary">
                    Download All
                  </Button>
                )}
                <Button onClick={handleCloseDialog} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
              <DialogTitle style={{ fontSize: "20px" }}>Confirm Delete</DialogTitle>
              <DialogContent>
                {selectedFileToDelete && (
                  <div>
                    <p>Are you sure you want to delete the file ?</p>
                    {/* <p>Are you sure you want to delete the file "{selectedFileToDelete.name}"?</p> */}
                  </div>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDeleteSingleFile(selectedFileToDelete)} color="primary">
                  Delete
                </Button>
                <Button onClick={handleCloseDeleteDialog} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={openDialogMail} onClose={handleDialogClose}>
        <DialogTitle>Assign Roles</DialogTitle>
        <DialogContent sx={{width:300}}>
          <TextField
            label="Incident ID"
            value={incidentId}
            fullWidth
            disabled
            margin="normal"
          />
          
          <InputLabel id="demo-simple-select-autowidth-label">Roles</InputLabel>
          <Select
           labelId="demo-simple-select-autowidth-label"
           id="demo-simple-select-autowidth"
           label="Roles"
            multiple
            placeholder="Roles"
            value={selectedRoles}
            onChange={handleRoleChange}
            fullWidth
            margin="normal"
            renderValue={(selected) => selected.join(', ')}
          >
            {rolesList.map((role) => (
              <MenuItem key={role.role_id} value={role.role_id}>
                <Checkbox checked={selectedRoles.indexOf(role.role_id) > -1} />
                <ListItemText  color="black" primary={role.role_name} />
              </MenuItem>
            ))}
          </Select>
         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDialogSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmittedFiles;