
export const base_url = "http://ec2-43-205-137-172.ap-south-1.compute.amazonaws.com:8071";
// export const base_url = "http://192.168.29.179:8071"

export const apis = {
    base: "http://ec2-43-205-137-172.ap-south-1.compute.amazonaws.com:8071",
    // base: "http://192.168.29.179:8071",


    POSTUSER_LOGIN_API: "/user/authenticate",

    GETMULTISEARCHALL: "/users/multiFormSearch?form_category=all&keyword=none&items_per_page=1111&page_number=1",
    GET_MULTISEARCH_ALL_API: "/users/multiFormSearch",
    GET_ROLES_API: "/roles/all",
    GET_USER_API: "/user/all",  
    DELETE_USER_API: "/user/",
    PUT_USER_API: "/user/",
    POST_USER_AUTHENTICATE_API: "/user/authenticate",
    POST_USERS_API: "/user",
    GET_USERSID_API: "/user/all",
    PUT_USER_BY_ID_API: "/user/",
    GET_ALL_ROLES_API: "/roles/all",
    GET_PROFILES_API: "/profile/profiles",
    GET_PROFILE_API: "/profile/all",
    GET_PROILE_PERMISSIONID_API: "/profile/permissionSet/",
    GET_ALL_PROFILE_API: "/profile/all",
    POST_FILEUPLOAD_API: "/incident/upload/create",
    GET_VIEWUPLOADEDFILE_API: "/incident/ById/",
    GET_ALL_INCIDENT_INCIDENTBYID_FILESZIP_API: "/incident/incidentId/filesZip",
    GET_INCIDENT_FILENAME_INCIDENTID_DOWNLOAD_API: "/incident/fileName/incidentId/download",
    POST_PROFILE_ADD_UPDATE_API: "/profile/addOrUpdate",
    PUT_PROFILE_ADD_UPDATE_API: "/profile/",
    GET_PROFILE_PERMISSION_API: "/profile/permissionsets",
    GET_INCIDENTS_ALL_API: "/incident/all",
    GET_INCIDENT_BYID_API: "/incident/ById/",
    PUT_INCIDENT_BYID_API: "/incident/",
    GET_ALL_DEPARTMENT_API: "/department/all",
    POST_DEPARTMENT_API: "/department",
    DELETE_DEPARTMENT_API: "/department/",
    GET_DEPARTMENTID_API: "/department/",
    PUT_DEPARTMENT_API: "/department/",
    GET_LOCATION_API: "/Location/all",
    GET_LOCATIONID_API: "/Location/",
    PUT_LOCATION_API: "/Location/",
    POST_LOCATIONS_API: "/Location/add",
    GET_ALL_LOCATION_API: "/Location/",
    DELETE_LOCATIONS_API: "/Location/",
    POST_ROLE_API: "/roles",
    GET_ROLE_BY_ID_API: "/roles/",
    PUT_ROLE_API: "/roles/",
    DELETE_ROLE_API: "/roles/",
    DELETE_PROFILE_API: "/profile/",
    POST_PROFILE_API: "/profile",
    GET_USERBYID_API: "/user/",
    GET_PROFILE_BY_ID_API: "/profile/",
    GET_PATIENT_EXPERIENCE_FORM_ALL: "/patient-experience-form/",
    PROFILE_FILTER_API: "/profile/filter",
    GET_INCIDENTS_ALL_API: "/incident/all",
    POST_INCIDENT_API: "/incident/upload/create",
    GET_INCIDENTACTIVITY_BYID_API: "/IncidentActivity/ByIncidentId/",
    GET_INCIDENT_INCIDENTID_FILEZIP_API: "/incident/incidentId/filesZip",
    GET_INCIDENT_FILENAME_INCIDENTID_DOWNLOAD_API: "/incident/fileName/incidentId/download",
    DELETE_INCIDENT_INCIDENTID_FILENAME_API: "/incident/incidentId/fileName",
    GET_LOCATION_AUDIT_API: "/Location/audit",
    GET_USER_AUDIT_API: "/user/audit",
    GET_INCIDENT_ALL_AUDIT_API: "/incident/all/audit",
    GET_DEPARTMENT_AUDIT_API: "/department/audit",
    GET_PERMISSION_AUDIT_API: "/profile/permissioSets/audit",
    UPDATE_INCIDENT_BY_ID: "/incident/",
    GET_INCIDENT_ACTIVITY_BY_INCIDENTID_API: "/IncidentActivity/ByIncidentId/",
    POST_ACTIVITY_API: "/IncidentActivity",
    POST_ACTIVITY_BY_CREATE_INCIDENT_API: "/IncidentActivity/createIncident",
    PUT_INCIDENT_ACTIVITY_BY_INCIDENT_ACTIVITYID: "/IncidentActivity/",
     GET_INCIDENTS_ALL_API: "/incident/all",
    GET_INCIDENT_FILTER_INCIDENT_COUNT_REPORT_API: "/incident/filter/incidents/count/report",
    PUT_INCIDENT_ACTIVITY_BY_INCIDENT_ACTIVITYID: "/IncidentActivity/",
    DELETE_INCIDENT_ACTIVITY_BY_INCIDENT_ACTIVITYID: "/IncidentActivity/",
    GET_INCIDENT_YEAR_MONTH_SEARCH_API: "/incident/year/month/search",
    GET_INCIDENT_YEAR_MONTH_DATE_SEARCH_API: "/incident/date/search?",
    GET_INCIDENTS_TYPE_SEARCH_API: "/incident/type/search?",
    GET_INCIDENT_YEAR_MONTH_DAY_COUNT: "/incident/count/years/months/days",
    GET_INCIDENT_BY_INCIDENTTYPECOUNT: "/incident/count/incident_type?",
    GET_INCIDENT_YEAR_MONTH_COUNT: "/incident/count/years/months",
    POST_INCIDENT_BY_UPLOAD_BYINCIDENTID: "/incident/upload/ByIncidentId",
    GET_INCIDENT_EXPORT_EXCEL: "/incident/Export/excel",
    GET_INCIDENT_EXPORT_PDF: "/incident/Export/pdf",
    GET_USER_AUDIT_EXPORT_PDF: "/user/Audit/Export/pdf",
    GET_USER_AUDIT_EXPORT_EXCEL: "/user/Audit/Export/excel",
    GET_LOCATION_AUDIT_EXPORT_PDF: "/location/Audit/Export/pdf",
    GET_LOCATION_AUDIT_EXPORT_EXCEL: "/location/Audit/Export/excel",
    GET_DEPARTMENT_AUDIT_EXPORT_PDF: "/department/Audit/Export/pdf",
    GET_DEPARTMENT_AUDIT_EXPORT_EXCEL: "/department/Audit/Export/excel",
    GET_INCIDENT_AUDIT_EXPORT_PDF: "/incident/Audit/Export/pdf",
    GET_INCIDENT_AUDIT_EXPORT_EXCEL: "/incident/Audit/Export/excel",
    GET_PROFILE_PERMISSIONSET_AUDIT_EXPORT_EXCEL: "/profile/Permissionset/Audit/Export/excel",
    GET_PROFILE_PERMISSIONSET_AUDIT_EXPORT_PDF: "/profile/Permissionset/Audit/Export/pdf",
    GET_USER_EXPORT_PDF: "/user/Export/pdf",
    GET_USER_EXPORT_EXCEL: "/user/Export/excel",
    GET_DEPARTMENT_EXPORT_PDF: "/department/Export/pdf",
    GET_DEPARTMENT_EXPORT_EXCEL: "/department/Export/excel",
    GET_LOCATION_EXPORT_PDF: "/location/Export/pdf",
    GET_LOCATION_EXPORT_EXCEL: "/location/Export/excel",
    GET_PROFILE_EXPORT_PDF: "/profile/Export/pdf",
    GET_PROFILE_EXPORT_EXCEL: "/profile/Export/excel",
    GET_NOTIFY_USERS_BY_INCIDENTID: "/incident/incidentinfo/notifyUsers/",
    GET_ALL_NOTIFICATIONS:"/IncidentActivity/all",
    PUT_NOTIFICATIONS_BY_INCIDENT_ACTIVITY_ID:"/IncidentActivity/"
};






