// import React, { useEffect, useState } from "react";
// import Checkbox from '@mui/material/Checkbox';
// import { apis } from "../../configuration/configurationAPI";
// import axios from "axios";
// import { Link, useParams } from "react-router-dom";
// import { Alert, Button, Snackbar, ClickAwayListener } from "@mui/material";
// import { Box } from "@material-ui/core";
// import { Navigate, useNavigate, } from "react-router-dom";
// import TextField from '@mui/material/TextField';
// import DatePicker from "react-datepicker";
// import store from "../../utils/index";
// import { useSelector } from 'react-redux';
// import { format, parse, isValid } from 'date-fns';
// import { commonSelectors } from '../../utils/commonstore';

// const dispatch = store.dispatch;
// let commonStore = store.getState().commonStore;
// store.subscribe(function () {
//     commonStore = store.getState().commonStore;
// });

// function AddPermissions() {
//     const { userId } = useParams();
//     const [permissionsetName, setPermissionsetName] = useState("");
//     const [fromDate, setFromDate] = useState("");
//     const [toDate, setToDate] = useState("");
//     const Navigate = useNavigate();
//     const [permissions, setPermissions] = useState(null);
//     const [addPermissions, setAddPermissions] = useState("");
//     const [userData, setUserData] = useState([]);
//     const [error, setError] = useState(null);
//     const [hasChanges, setHasChanges] = useState(false);
//     const [fromDateFilter, setFromDateFilter] = useState(null);
//     const [toDateFilter, setToDateFilter] = useState(null);
//     const [profileId, setProfileId] = useState(-1);
//     const [snackbar, setSnackbar] = useState({
//         open: false,
//         message: "",
//         severity: "success",
//     });
//     const baererToken = useSelector(commonSelectors.selectBaererToken);
//     const loggedinId = useSelector(commonSelectors.selectLoginUserId);
//     const [userName, setUserName] = useState("");

//     const loginUserId = useSelector(commonSelectors.selectLoginUserId);

//     const [usersId, setUsersId] = useState("");
//     // useEffect(() => {

//     //     axios.get(apis.base + apis.GET_USERBYID_API + userId, {
//     //         headers: {
//     //             userid: loggedinId,
//     //             Authorization: "Bearer " + baererToken

//     //         }
//     //     })
//     //         .then((response) => {
//     //             setUserData(response.data);
//     //             setUserName(response.data.user_name);
//     //             setUsersId(response.data.user_id);
//     //             axios.get(apis.base + apis.GET_PROFILE_BY_ID_API + response.data.profile_id, {
//     //                 headers: {
//     //                     userid: loggedinId,
//     //                     Authorization: "Bearer " + baererToken

//     //                 }
//     //             })
//     //                 .then((response) => {
//     //                     const { dashboard, audit, forms, submitted_files, roles, users, profiles, departments, permission_list, locations, reports, exports, profile_name, valid_from, valid_to } = response.data;
//     //                     setPermissions({ dashboard, audit, forms, submitted_files, roles, users, profiles, departments, permission_list, locations, reports, exports });
//     //                     setError(null);
//     //                     setPermissionsetName(response.data.profile_name);
//     //                     setProfileId(response.data.profile_id)
//     //                     const parsedFromDate = parse(valid_from, 'yyyy-MM-dd', new Date());
//     //                     const parsedToDate = parse(valid_to, 'yyyy-MM-dd', new Date());

//     //                     if (isValid(parsedFromDate)) setFromDateFilter(parsedFromDate);
//     //                     if (isValid(parsedToDate)) setToDateFilter(parsedToDate);
//     //                 })
//     //                 .catch((error) => {
//     //                     console.error("Error fetching profile details:", error);
//     //                     setError("Error while fetching profile details. Please try again.");
//     //                 });

//     //         })
//     //         .catch((error) => {
//     //             console.error("Error fetching user data:", error);
//     //         });



//     // }, []);

//     const [showPopup, setShowPopup] = useState(false);

//     const closePopup = () => {
//         setShowPopup(false);
//     };


//     const handleCheckboxChange = (key, index, isChecked) => {
//         setAddPermissions(prev => {
//             const updatedPermissions = { ...prev };
//             if (!updatedPermissions[key]) {
//                 updatedPermissions[key] = { ...permissions[key] };
//             }
//             updatedPermissions[key][index] = isChecked ? '1' : '0';
//             setHasChanges(true);
//             return updatedPermissions;
//         });
//     };
//     const flattenObjectValues = (obj) => {
//         return Object.values(obj || {}).join('');
//     };
//     const handleAddPermissions = () => {
//         const flattenedPermissions = {};
//         for (const [key, value] of Object.entries(addPermissions)) {
//             flattenedPermissions[key] = typeof value === 'object' ? flattenObjectValues(value) : value;
//         }


//         const permissionsData = {
//             profile_id: profileId,
//             profile_name: permissionsetName,
//             is_deleted: false,
//             type: "permission_set",
//             valid_from: format(fromDateFilter, 'yyyy-MM-dd'),
//             valid_to: format(toDateFilter, 'yyyy-MM-dd'),
//             created_by: loggedinId,
//             updated_by: loggedinId,
//             assigned_to: JSON.parse(userId),
//             ...flattenedPermissions,
//         };

//         axios.post(apis.base + apis.POST_PROFILE_API, permissionsData, {
//             headers: {
//                 userid: loginUserId,
//                 Authorization: "Bearer " + baererToken
//             },
//             params: {
//                 postType: 'post'
//             },
//         })
//             .then((res) => {
//                 console.log("Response after posting permissions:", res.data);
//                 setSnackbar({
//                     open: true,
//                     message: 'Permissions submitted successfully',
//                     severity: 'success',
//                 });
//                 <Link to='/permissionsetlist' />
//                 callback(res);
//             })
//             .catch((err) => {
//                 console.error("Error submitting permissions:", err);
//                 setSnackbar({
//                     open: true,
//                     message: 'Submission failed',
//                     severity: 'error',
//                 });
//             });
//     };

//     const callback = (res) => {

//     };
//     const handleSnackbarClose = () => {
//         Navigate("/permissionsetlist")
//         setSnackbar({ ...snackbar, open: false });
//     };

//     return (
//         <div className="page-content">
//             <div className="page-inner">
//                 <div className="panel panel-white">
//                     <div className="page-title">
//                         <h3>Permissionset List</h3>
//                         <div className="page-breadcrumb">
//                             <ol className="breadcrumb">
//                                 <li><Link to="/Dashboard">Home</Link></li>
//                                 <li>Permissionset List</li>
//                             </ol>
//                         </div>
//                     </div>
//                     <div id="main-wrapper">
//                         <div className="row m-t-md">
//                             <div className="col-md-12">
//                                 <ClickAwayListener onClickAway={() => { }}>
//                                     <Snackbar
//                                         open={snackbar.open}
//                                         onClose={() => setSnackbar({ ...snackbar, open: false })}
//                                         style={{ position: 'fixed', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}
//                                     >
//                                         <Alert onClose={handleSnackbarClose} severity={snackbar.severity} style={{ width: '300px', height: '40px', fontSize: '12px' }}>
//                                             {snackbar.message}
//                                         </Alert>
//                                     </Snackbar>
//                                 </ClickAwayListener>
//                                 <div className="card-header card-header-secondary" >
//                                     <div className="forms-sample">
//                                         <div className="row">
//                                             <div className="col-md-3">
//                                                 <div className="custom-dropdown">
//                                                     <label style={{ fontWeight: 'bold' }}>Add Permission Set Name</label>
//                                                     <TextField
//                                                         type="text"
//                                                         variant="outlined"
//                                                         fullWidth
//                                                         inputProps={{ style: { height: "5px" } }}
//                                                         value={permissionsetName}
//                                                         onChange={(e) => setPermissionsetName(e.target.value)}
//                                                     />
//                                                 </div>
//                                             </div>
//                                             {/* <div className="col-md-2" style={{ marginRight: '1rem' }}>
//                                     <label style={{  display: 'block',fontWeight:'bold' }}>UserName:</label>
//                                     <span>{userData.user_name}</span>
//                                 </div> */}
//                                             {/* <div className="col-md-2">
//                                     <label htmlFor="fromDate" className="form-label" style={{fontWeight:'bold'}}>From Date</label>
//                                     <DatePicker
//                                     className='form-control'
//                                     fullWidth
//                                     selected={fromDateFilter}
//                                     onChange={date => {
//                                         if (date && isValid(date)) {
//                                             setFromDateFilter(date);
//                                         }
//                                     }}
//                                     dateFormat="MM/dd/yyyy"
//                                     placeholderText="MM/DD/YYYY"
//                                     maxDate={new Date()}
//                                 />
//                                 </div> */}
//                                             {/* <div className="col-md-2">
//                                     <label htmlFor="toDate" className="form-label" style={{fontWeight:'bold'}}>To Date</label>
//                                     <DatePicker
//                                     id="toDate"
//                                     className="form-control"
//                                     selected={toDateFilter}
//                                     isClearable
//                                     placeholderText="MM/DD/YYYY"
//                                     showYearDropdown
//                                     scrollableYearDropdown
//                                     onChange={date => {
//                                         if (date && isValid(date)) {
//                                             setToDateFilter(date);
//                                         }
//                                     }}
//                                     dateFormat="MM/dd/yyyy"
//                                 />
//                                 </div> */}
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="panel-body">
//                                     {error && <Box bgcolor="white" color="red" p={2} mb={2} border={1} borderColor="black">
//                                         {error}
//                                     </Box>}
//                                     {!error && (
//                                         <div className="table-responsive">
//                                                 <table id="example" className="display table" style={{ width: "100%" }}>
//                                                     <thead style={{ backgroundColor: "rgb(35 212 182)" }}>
//                                                         <tr>
//                                                             <th> </th>
//                                                             {/* <th>TAB</th> */}
//                                                             <th>READ</th>
//                                                             <th>CREATE</th>
//                                                             <th>EDIT</th>
//                                                             <th>DELETE</th>
//                                                         </tr>
//                                                     </thead>
//                                                     <tbody>
//                                                         {Object.entries(permissions).map(([key, permissions]) => (
//                                                             <tr key={key}>
//                                                                 <td>{key.toUpperCase()}</td>
//                                                                 {typeof permissions === "string" ? (
//                                                                     permissions.split("").map((value, index) => {
//                                                                         if (!addPermissions[key] || (addPermissions[key][index] == undefined))
//                                                                             (value == "1") && handleCheckboxChange(key, index, value)
//                                                                         return (
//                                                                             <td key={index}>
//                                                                                 <Checkbox
//                                                                                     checked={(addPermissions[key] && addPermissions[key][index] === "1")}
//                                                                                     onChange={(e) => handleCheckboxChange(key, index, e.target.checked)}
//                                                                                     style={{
//                                                                                         color: value == "1" ? "blue" : "inherit",

//                                                                                     }}
//                                                                                 />
//                                                                             </td>
//                                                                         )
//                                                                     })
//                                                                 ) : (
//                                                                     <td colSpan="5">Invalid permissions data</td>

//                                                                 )}
//                                                             </tr>
//                                                         ))}


//                                                     </tbody>
//                                                 </table>

//                                         </div>
//                                     )}
//                                     {/* <Button variant="outlined" style={{ backgroundColor: "#22baa0" }} onClick={handleAddPermissions} disabled={!hasChanges}>Submit</Button> */}
//                                     {/* <div className="panel-body" style={{ display: 'flex' }}>
//                             <div>
//                                 <Button variant="contained" type="button" className="btn btn-primary" style={{ backgroundColor: "#22baa0", marginLeft: '20px', color:"white"}} onClick={handleAddPermissions}>Submit</Button>
//                             </div>
//                             <div>
//                                 <Link to="/permissionsetlist">
//                                     <Button type="button" variant="contained" className="btn btn-success" style={{ marginLeft: '30px', backgroundColor: "#34425a" }} >Cancel</Button>
//                                 </Link>
//                             </div>
//                         </div> */}
//                                     {permissions && Object.keys(permissions).length > 0 && (
//                                         <div className="panel-body" style={{ display: 'flex' }}>
//                                             <div>
//                                                 <Button variant="contained" type="button" className="btn btn-primary" style={{ backgroundColor: "#22baa0", marginLeft: '20px', color: "white" }} onClick={handleAddPermissions} disabled={!hasChanges}>Submit</Button>
//                                             </div>
//                                             <div>
//                                                 <Link to="/permissionsetlist">
//                                                     <Button type="button" variant="contained" className="btn btn-success" style={{ marginLeft: '30px', backgroundColor: "#34425a" }}>Cancel</Button>
//                                                 </Link>
//                                             </div>
//                                         </div>
//                                     )}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default AddPermissions;


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Button, Checkbox, Snackbar, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import { apis } from "../../configuration/configurationAPI";
import store from "../../utils/index";
import { commonSelectors } from '../../utils/commonstore';

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
    commonStore = store.getState().commonStore;
});

function AddPermissions() {
    const [permissionsetName, setPermissionsetName] = useState("");
    const [permissions, setPermissions] = useState({
        dashboard: "0",
        audit: "0",
        forms: "0000",
        submitted_files: "0000",
        workflow: "0000",
        roles: "0000",
        users: "0000",
        profiles: "0000",
        departments: "0000",
        permission_list: "0000",
        locations: "0000",
        reports: "0000",
        exports: "0",
    });
    const [hasChanges, setHasChanges] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });

    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loggedinId = useSelector(commonSelectors.selectLoginUserId);
    const Navigate = useNavigate();

    const handleCheckboxChange = (key, index, isChecked) => {
        setPermissions((prev) => {
            const updatedPermissions = { ...prev };
            const permissionArray = updatedPermissions[key].split('');

            if (index > 0 && isChecked) {
                permissionArray[0] = '1'; 
            }
            permissionArray[index] = isChecked ? '1' : '0';

            updatedPermissions[key] = permissionArray.join('');
            setHasChanges(true);
            return updatedPermissions;
        });
    };

    const flattenObjectValues = (obj) => {
        return Object.values(obj || {}).join('');
    };

    const handleAddPermissions = () => {
        // Validation for mandatory field
        if (!permissionsetName.trim()) {
            setSnackbar({
                open: true,
                message: 'Profile Name is required',
                severity: 'error',
            });
            return;
        }

        const flattenedPermissions = {};
        for (const [key, value] of Object.entries(permissions)) {
            flattenedPermissions[key] = typeof value === 'object' ? flattenObjectValues(value) : value;
        }

        const permissionsData = {
            profile_name: permissionsetName,
            is_deleted: false,
            type: "profile",
            created_by: loggedinId,
            ...flattenedPermissions,
        };

        axios.post(apis.base + apis.POST_PROFILE_API, permissionsData, {
            headers: {
                userid: loggedinId,
                Authorization: "Bearer " + baererToken
            },
            params: {
                postType: 'post'
            },
        })
        .then((res) => {
            // console.log("Response after posting permissions:", res.data);
            setSnackbar({
                open: true,
                message: 'Profile submitted successfully',
                severity: 'success',
            });
            Navigate('/profilelist');
        })
        .catch((err) => {
            console.error("Error submitting Profile:", err);
            setSnackbar({
                open: true,
                message: 'Submission failed',
                severity: 'error',
            });
        });
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <div className="page-content">
            <div className="page-inner">
                <div className="panel panel-white">
                    <div className="page-title">
                        <h3>Create Profile</h3>
                    </div>
                    <div className="panel-body">
                        <div className="forms-sample">
                            <Snackbar
                                open={snackbar.open}
                                autoHideDuration={6000}
                                onClose={handleSnackbarClose}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                style={{ marginTop: '20px' }}
                            >
                                <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                                    {snackbar.message}
                                </Alert>
                            </Snackbar>
                            <div className="row" style={{ marginBottom: "10px" }}>
                                <div className="col-md-3">
                                    <TextField
                                        label="Profile Name"
                                        variant="outlined"
                                        fullWidth
                                        value={permissionsetName}
                                        onChange={(e) => setPermissionsetName(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="display table" style={{ width: "100%" }}>
                                <thead style={{ backgroundColor: "rgb(35 212 182)" }}>
                                    <tr>
                                        <th>Profile</th>
                                        <th>READ</th>
                                        <th>CREATE</th>
                                        <th>EDIT</th>
                                        <th>DELETE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(permissions).map(([key, value]) => (
                                        <tr key={key}>
                                            <td>{key.toUpperCase()}</td>
                                            {value.split('').map((val, index) => (
                                                <td key={index}>
                                                    <Checkbox
                                                        checked={val === '1'}
                                                        onChange={(e) => handleCheckboxChange(key, index, e.target.checked)}
                                                    />
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="panel-body" style={{ display: 'flex' }}>
                            <Button
                                variant="contained"
                                className="btn btn-primary"
                                style={{ backgroundColor: "#22baa0", marginLeft: '20px', color: "white" }}
                                onClick={handleAddPermissions}
                                disabled={!hasChanges}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="contained"
                                className="btn btn-success"
                                style={{ marginLeft: '30px', backgroundColor: "#34425a" }}
                                onClick={() => Navigate("/profilelist")}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddPermissions;
