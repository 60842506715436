import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Tree, TreeNode } from 'react-organizational-chart';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, TextField, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Alert, List, ListItem, ListItemText } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import VisibilityIcon from '@material-ui/icons/Visibility';
import axios from 'axios';
import { apis } from '../../configuration/configurationAPI';
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import store from "../../utils/index";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";


const TreeNodeContainer = styled.div`
    display:inline-flex;
    align-items: center;
    position: relative;
  `;


const StyledNode = styled.div`
  padding: 5px;
  cursor: pointer;
  position: relative;
  border: 2px solid black;
  border-radius: 4px;
  background: ${props => props.isDeletable ? '#22baa0' : '#34425a'};
  color: white;
  font-weight: bold;
`;


const AddSiblingIconContainer = styled.div`
    padding: 10px;
    width: 40px; 
    height: 40px;
    position: relative;
  `;

const AddSiblingIcon = styled(AddCircleIcon)`
    font-size: 50px;
    cursor: pointer;
    position: absolute;
    top: 35px;
    left: 50px;
  `;

const ButtonsContainer = styled.div`
    display: ${props => props.show ? 'flex' : 'none'};
    align-items: center;/* Ensure buttons are above other elements */
`;

const InfoIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `;





const HierarchyNode = ({ node, handleAddNode, handleDeleteNode, handleEditNode, handleAddSiblingNode, isParentDeleted = false, deletedNodes }) => {
  const accessInfo = useSelector(commonSelectors.selectAccessInfo);
  const loginUserId = useSelector(commonSelectors.selectLoginUserId);
  const baererToken = useSelector(commonSelectors.selectBaererToken);
  const [showButtons, setShowButtons] = useState(false);
  const [showAddSiblingIcon, setShowAddSiblingIcon] = useState(false);
  const [addingRole, setAddingRole] = useState(false);
  const [editingRole, setEditingRole] = useState(false);
  const [newRoleLabel, setNewRoleLabel] = useState('');
  const [previousRoleLabel, setPreviousRoleLabel] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  const [isRightSibling, setIsRightSibling] = useState(true);
  const [addingSibling, setAddingSibling] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedNodeToDelete, setSelectedNodeToDelete] = useState(null);

  const [profileListOpen, setProfileListOpen] = useState(false);
  const [profileList, setProfileList] = useState([]);
  const [selectedProfileId, setSelectedProfileId] = useState(null);

  const handleOpenProfileList = () => {
    axios
      .get(apis.base + apis.GET_ALL_PROFILE_API, {
        headers: {
          userId: loginUserId,
          Authorization: "Bearer " + baererToken,
        },
      })
      .then((response) => {
        const profiles = response.data.map((item) => ({
          id: item.profile_info?.id,
          name: item.profile_info?.profile_name || "Unknown Profile",
        }));
        setProfileList(profiles);
        setProfileListOpen(true);
      })
      .catch((error) => {
        console.error("Error fetching profiles:", error);
      });
  };
  useEffect(() => {
    if (selectedProfileId !== node.profile_id) {
      axios.put(apis.base + apis.PUT_ROLE_API + node.role_id, { profile_id: selectedProfileId }, {
        headers: {
          userid: loginUserId,
          Authorization: "Bearer " + baererToken,
        },
      })
      .then((res) => {
        console.log("Role updated with profile:", res.data);
        navigate(0);
      })
      .catch((error) => {
        console.error("Error while updating profile:", error);
      });
    }
  }, [selectedProfileId, node.role_id, loginUserId, baererToken]);
  
  const handleProfileListClose = () => {
    setProfileListOpen(false);
  };

  const handleProfileSelect = (profileId) => {
    setSelectedProfileId(profileId);
    console.log('Selected Profile ID:', profileId);
    setProfileListOpen(false);
  };

  const navigate = useNavigate();
  const [showEyeIcon, setShowEyeIcon] = useState(false);


  const rolesWithoutActions = ['CEO', 'CMO', 'RM'];

  const isRestrictedRole = rolesWithoutActions.includes(node.role_name);

  const handleDeleteIconClick = (node) => {
    setSelectedNodeToDelete(node);
    setDeleteDialogOpen(true);
  };



  const handleDeleteConfirmed = () => {
    if (selectedNodeToDelete) {
      handleDeleteNode(selectedNodeToDelete.role_id);
    }
    setDeleteDialogOpen(false);
  };


  const handleDeleteCancelled = () => {
    setSelectedNodeToDelete(null);
    setDeleteDialogOpen(false);
    setAddingRole(false);
    setAddingSibling(false);
    setEditingRole(false);
  };


  const handleAddRole = () => {
    setAddingRole(true);
    setShowButtons(true);
  };

  const handleEditRole = () => {
    setPreviousRoleLabel(node.label);
    setEditingRole(true);
    setShowButtons(!showButtons);
    setNewRoleLabel(node.role_name);
  };

  const handleAddSibling = (isRightSibling) => {
    setAddingSibling(true);
    setShowButtons(false);
    setShowAddSiblingIcon(false);
    setNewRoleLabel('');
    setIsRightSibling(isRightSibling);
  };

  const handleSubmit = () => {
    if (newRoleLabel.trim() !== '') {
      if (addingRole) {
        // Pass the correct role_id for the new role being added
        handleAddNode(node.role_id, newRoleLabel);
        setAddingRole(false);
      } else if (editingRole) {
        // When editing, ensure role_id remains intact
        handleEditNode(node.role_id, newRoleLabel);  // Pass the same role_id
        setEditingRole(false);
      } else if (addingSibling) {
        handleAddSiblingNode(node.role_id, newRoleLabel, isRightSibling);
        setAddingSibling(false);
      }
      // Clear input fields after submission
      setNewRoleLabel('');
      setPreviousRoleLabel('');
    }
  };

  const handleInputChange = (event) => {
    setNewRoleLabel(event.target.value);
  };

  const handleEditInputChange = (event) => {
    setNewRoleLabel(event.target.value);
  };

  if (isDeleted) return null;

  const roleAccess = {
    // "CEO": "Has full access to view, create, edit, and delete across the entire application.",
    // "CMO": "Has access to dashboard, forms, submitted files, roles, users, departments, and exports.",
    // "RM": "Has access to roles, forms, users, departments and management.",
    // "QUALITY MANAGER": "Has access to forms and reports.",
    // "PATIENTS ADVOCATE": "Has access to patient-related forms and reports.",
    // "DIRECTOR OF DEPARTMENT": "Has access to department-specific forms, users, and reports.",
  };

  const handleNodeClick = () => {
    setShowButtons(!showButtons);
    setShowAddSiblingIcon(!showAddSiblingIcon);
  };

  const handleNodeClickProfile = (node) => {
    const generateRolesMapping = (node) => {
      const rolesMapping = {};
    if (node.profile_id && typeof node.profile_id === "number") {

      rolesMapping[node.profile_id] = `/viewprofile/${node.profile_id}`;
    }
    return rolesMapping;
  };
  
    console.log("node:", node);
    const rolesMapping = generateRolesMapping(node);
    const profileId = node.profile_id;
    const navigateTo = rolesMapping[profileId];
  
    if (navigateTo) {
      navigate(navigateTo); 
    } else {
      console.error(`No navigation path found for profile ID: ${profileId}`);
    }
  };
  

  const handleNodeClickAllRoles = (node) => {
    const rolesWithEyeIcon = ["CEO", "CMO", "RM", "QUALITY MANAGER", "PATIENTS ADVOCATE", "DIRECTOR OF DEPARTMENT"];

    {
      handleNodeClick();
    }
    if (rolesWithEyeIcon.includes(node.role_name)) {
      setShowEyeIcon((prevRole) => (prevRole === node.role_name ? null : node.role_name));
    }
  };

  const VIEW_ROLE = ["1000", "1001", "1010", "1011", "1100", "1101", "1110", "1111"];
  const ADD_ROLE = ["1100", "1101", "1110", "1111"];
  const EDIT_ROLE = ["1010", "1011", "1110", "1111"];
  const DELETE_ROLE = ["1001", "1011", "1101", "1111"];

  const userRoles = accessInfo && accessInfo.roles ? accessInfo.roles.split(',') : [];

  const hasAccess = (rolesArray) => {
    return userRoles.some(role => rolesArray.includes(role));
  };


  const canView = hasAccess(VIEW_ROLE);
  const canAdd = hasAccess(ADD_ROLE);
  const canEdit = hasAccess(EDIT_ROLE);
  const canDelete = hasAccess(DELETE_ROLE);

  return (
    <TreeNode
      label={
        <TreeNodeContainer>
          {showAddSiblingIcon && canAdd && (
            <AddSiblingIconContainer>
              <AddSiblingIcon
                onClick={() => handleAddSibling(false)}
                style={{ fontSize: '20px' }}
              />
            </AddSiblingIconContainer>
          )}
          <div
            // title={
            //   <span
            //     style={{
            //       fontSize: '11px',
            //       fontWeight: 'bold',
            //       lineHeight: '1.5em',
            //     }}
            //   >
            //     {roleAccess[node.role_name] || ''}
            //   </span>
            // }
            arrow
            placement={
              node.role_name === 'DIRECTOR OF DEPARTMENT' || node.role_name === 'PATIENTS ADVOCATE'
                ? 'top'
                : 'left'
            }
          >
            <StyledNode
              onClick={() => handleNodeClickAllRoles(node)}
              isDeletable={node.is_deletable}
            >
              {node.role_name}
            </StyledNode>
          </div>

          {/* Show the eye icon conditionally */}
          {showAddSiblingIcon && canView && canAdd && (
            <VisibilityIcon
              style={{
                fontSize: '20px',
                cursor: 'pointer',
                marginLeft: '5px',
                verticalAlign: 'middle',
              }}
              onClick={() => handleNodeClickProfile(node)}
            />
          )}
          {showAddSiblingIcon && canAdd && (
            <Button
              variant="outlined"
              style={{
                marginLeft: '10px',
                height: '30px',
                fontSize: '12px',
                verticalAlign: 'middle',
              }}
              onClick={handleOpenProfileList}
            >
              Attach Profile
            </Button>
          )}

          {/* Modal for Profile List */}
          <Dialog open={profileListOpen} onClose={handleProfileListClose}>
            <DialogTitle>Select a Profile</DialogTitle>
            <DialogContent>
              <List>
                {profileList.map((profile) => (
                  <ListItem
                    key={profile.id}
                    button
                    onClick={() => handleProfileSelect(profile.id)}
                  >
                    <ListItemText style={{ color: "black" }} primary={profile.name} />
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleProfileListClose} style={{ fontSize: '14px' }}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>


          {/* Show the add, edit, delete icons conditionally */}
          <ButtonsContainer show={showButtons} className="buttons-container">
            {/* {!isRestrictedRole && ( */}
            <>
              {['QUALITY MANAGER', 'PATIENTS ADVOCATE', 'DIRECTOR OF DEPARTMENT'].includes(node.role_name) ? (
                <>
                  {/* Add icon for specific roles */}
                  {canAdd && (
                    <AddCircleIcon
                      style={{
                        fontSize: '20px',
                        cursor: 'pointer',
                        marginLeft: '5px',
                      }}
                      onClick={handleAddRole}
                    />
                  )}
                </>
              ) : (
                <>
                  {canAdd && (
                    <AddCircleIcon
                      style={{
                        fontSize: '20px', cursor: 'pointer', marginLeft: '5px',
                      }}
                      onClick={handleAddRole}
                    />
                  )}
                  {canEdit && (
                    <BorderColorIcon
                      style={{
                        fontSize: '20px', cursor: 'pointer', marginLeft: '5px',
                      }}
                      onClick={handleEditRole}
                    />
                  )}
                  {canDelete && (
                    <DeleteIcon
                      style={{
                        fontSize: '20px', cursor: 'pointer', marginLeft: '5px',
                      }}
                      onClick={() => handleDeleteIconClick(node)}
                    />
                  )}
                </>
              )}
            </>
            {/* )} */}
          </ButtonsContainer>

          <Dialog open={deleteDialogOpen} onClose={handleDeleteCancelled}>
            <DialogTitle style={{ fontSize: '20px' }}>Delete Role</DialogTitle>
            <DialogContent>
              <DialogContentText style={{ fontSize: '15px' }}>
                Are you sure you want to delete this Role?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleDeleteCancelled}
                style={{ fontSize: '15px' }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleDeleteConfirmed}
                variant="contained"
                style={{ fontSize: '15px', backgroundColor: '#27b19a' }}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </TreeNodeContainer>
      }
      key={node.role_id}
    >
      {(addingRole || addingSibling || editingRole) && (
        <div>
          <TextField
            value={newRoleLabel}
            onChange={
              addingRole || addingSibling ? handleInputChange : handleEditInputChange
            }
          />
          <Button
            style={{
              height: '47px',
              backgroundColor: '#22baa0',
              color: 'white',
            }}
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Button
            style={{
              height: '47px',
              backgroundColor: '#34425a',
              color: 'white',
            }}
            variant="contained"
            onClick={handleDeleteCancelled}
          >
            Cancel
          </Button>
        </div>
      )}
      {Array.isArray(node.children)
        ? node.children.map((childNode) => (
          <HierarchyNode
            key={childNode.role_id}
            node={childNode}
            handleAddNode={handleAddNode}
            handleDeleteNode={handleDeleteNode}
            handleEditNode={handleEditNode}
            handleAddSiblingNode={handleAddSiblingNode}
            deletedNodes={deletedNodes}
          />
        ))
        : null}
    </TreeNode>
  );

};


// information icon 
const InfoTooltip = ({ info, text }) => (
  <Tooltip title={info} interactive style={{ fontSize: "25px", marginLeft: "90%" }}>
    <InfoIconContainer>
      <InfoIcon style={{ cursor: 'pointer', fontSize: "25px", marginTop: "1%" }} />
      <span style={{ marginLeft: '5px' }}>{text}</span>
    </InfoIconContainer>
  </Tooltip>
);

const Roles = () => {
  const [hierarchyData, setHierarchyData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const baererToken = useSelector(commonSelectors.selectBaererToken);
  const loginUserId = useSelector(commonSelectors.selectLoginUserId);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    setIsLoading(true);
    axios.get(apis.base + apis.GET_ALL_ROLES_API, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((res) => {
        const roles = res.data;
        const hierarchy = convertToHierarchy(roles);
        setHierarchyData(hierarchy);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
        setSuccessMessage('');
        setErrorMessage('Failed to load roles.');
      });
     
  }, []);

  const convertToHierarchy = (roles) => {
    const roleMap = {};
    let root;

    roles.forEach(role => {
      roleMap[role.role_id] = { ...role, children: [] };
    });


    roles.forEach(role => {
      if (role.parent !== null && roleMap[role.parent]) {
        roleMap[role.parent].children.push(roleMap[role.role_id]);
      } else {
        root = roleMap[role.role_id];
      }
    });

    const setLevel = (node, level) => {
      node.level = level;
      node.children.forEach(child => setLevel(child, level + 1));
    };

    setLevel(root, 0);

    return root;
  };


  const handleAddNode = (parentKey, newRoleName) => {
    const data = {
      role_id: 0,
      role_name: newRoleName,
      parent: parentKey,
      is_deletable: true,
      is_deleted: false,
      created_by: loginUserId,
      updated_by: loginUserId,
      created_timestamp: new Date().toISOString(),
      updated_timestamp: new Date().toISOString(),
      level: 0
    };
    setIsLoading(true);
    axios.post(apis.base + apis.POST_ROLE_API, data, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((res) => {
        const newRole = res.data;
        newRole.children = [];

        const findParentNode = (node, parentId) => {
          if (node.role_id === parentId) {
            return node;
          } else if (Array.isArray(node.children)) {
            for (const childNode of node.children) {
              const foundNode = findParentNode(childNode, parentId);
              if (foundNode) return foundNode;
            }
          }
          return null;
        };

        const parentNode = findParentNode(hierarchyData, parentKey);

        if (parentNode) {
          data.level = parentNode.level + 1;
        }

        setHierarchyData(prevData => {
          const updateHierarchy = (node) => {
            if (node.role_id === parentKey) {
              return {
                ...node,
                children: [...(node.children || []), newRole]
              };
            } else if (Array.isArray(node.children)) {
              return {
                ...node,
                children: node.children.map(updateHierarchy)
              };
            } else {
              return node;
            }
          };

          return updateHierarchy(prevData);
        });
        setIsLoading(false);
        setSnackbar({
          open: true,
          message: 'Role added successfully',
          severity: 'success',
        });
        // console.log('Role added:',);
        setTimeout(() => {
          setSnackbar('');
        }, 1000);
      })
      .catch((error) => {
        console.error('Error adding role:', error);
        setIsLoading(false);
        setSnackbar({
          open: true,
          message: 'Failed to added role',
          severity: 'error',
        });
        console.error("Error submitting the role:", error);
      });
  };

  const handleDeleteNode = (keyToRemove) => {
    console.log('Deleting node with key:', keyToRemove);
    axios.delete(apis.base + apis.DELETE_ROLE_API + `${keyToRemove}`, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then(() => {
        const removeNode = (node) => {
          if (node.role_id === keyToRemove) {
            return node.children || [];
          } else if (Array.isArray(node.children)) {
            const updatedChildren = node.children.map(childNode => removeNode(childNode)).filter(Boolean);
            return { ...node, children: updatedChildren };
          } else {
            return node;
          }
        };
        setHierarchyData(prevData => {
          // console.log('Updating hierarchy data:', prevData);
          const updatedData = removeNode(prevData);
          // console.log('Updated hierarchy data:', updatedData);
          return updatedData;
        });
        setSnackbar({
          open: true,
          message: 'Role deleted successfully',
          severity: 'success',
        });
        setTimeout(() => {
          setSnackbar('');
        }, 2000);
      })
      .catch((error) => {
        console.error('Error deleting role:', error);
        setSnackbar({
          open: true,
          message: 'Failed to delete role',
          severity: 'error',
        });
        setTimeout(() => {
          setSnackbar('');
        }, 6000);
      });
  };

  const handleEditNode = (keyToEdit, newRoleName) => {
    const findAndEditNode = (node) => {
      if (node.role_id === keyToEdit) {
        return { ...node, role_name: newRoleName };
      } else if (Array.isArray(node.children)) {
        return { ...node, children: node.children.map(findAndEditNode) };
      }
      return node;
    };

    const updatedHierarchyData = findAndEditNode(hierarchyData);
    setHierarchyData(updatedHierarchyData);

    const data = {
      role_id: keyToEdit,
      role_name: newRoleName,
      is_deleted: false,
      updated_by: loginUserId,
    };

    setIsLoading(true);
    axios.put(apis.base + apis.PUT_ROLE_API + `${keyToEdit}`, data, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then(() => {
        setSuccessMessage('Role updated successfully');
        setSnackbar({
          open: true,
          message: 'Role updated successfully',
          severity: 'success',
        });
        setTimeout(() => {
          setSuccessMessage('');
        }, 1000);
      })
      .catch((error) => {
        console.error('Error updating role:', error);
        setErrorMessage('Failed to update role');
        setSnackbar({
          open: true,
          message: 'Failed to update role',
          severity: 'error',
        });
        setTimeout(() => {
          setErrorMessage('');
        }, 6000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAddSiblingNode = (parentKey, newRoleLabel, isRightSibling) => {
    const data = {
      role_id: 0,
      role_name: newRoleLabel,
      parent: parentKey,
      is_deletable: true,
      is_deleted: false,
      created_by: loginUserId,
      updated_by: loginUserId,
      created_timestamp: new Date().toISOString(),
      updated_timestamp: new Date().toISOString(),
      level: 0
    };

    const insertIndex = isRightSibling ? 1 : 0;
    axios.post(apis.base + apis.POST_ROLE_API, data, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((res) => {
        const newRole = res.data;
        newRole.children = [];
        setHierarchyData(prevData => {
          const updateHierarchy = (node) => {
            if (node.role_id === parentKey) {
              return {
                ...node,
                children: [...(node.children || []).slice(0, insertIndex), newRole, ...(node.children || []).slice(insertIndex)]
              };
            } else if (Array.isArray(node.children)) {
              return {
                ...node,
                children: node.children.map(updateHierarchy)
              };
            } else {
              return node;
            }
          };

          return updateHierarchy(prevData);
        });
        setIsLoading(false);
        setSnackbar({
          open: true,
          message: 'Role added successfully',
          severity: 'success',
        });
        setTimeout(() => {
          setSnackbar('');
        }, 1000);
      })
      .catch((error) => {
        console.error('Error adding role:', error);
        setIsLoading(false);
        setSnackbar({
          open: true,
          message: 'Role failed',
          severity: 'success',
        });
        setTimeout(() => {
          setSnackbar('');
        }, 3000);
      });
  };

  return (
    <div className='page-content' >
      <div className="page-inner"  >
        <div className="page-title">
          <h3>Roles</h3>
          <div className="page-breadcrumb">
            <ol className="breadcrumb">

              <li><Link to="/Dashboard">Home</Link></li>
              {/* <li>User Management</li> */}
              <li>Roles </li>
            </ol>
          </div>
        </div>
        <div class="col-md-12 ">
            <div class="col-md-12">
        <div id="main-wrapper">
          <div className="panel panel-white" style={{ padding: '20px' }}>
            <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px', padding: '5px', marginTop: '10px', color: "#74767d" }}>ROLE HIERARCHY FOR INCIDENT REPORTING</p>
            {isLoading && <CircularProgress />}
            <Snackbar
              open={snackbar.open}
              autoHideDuration={6000}
              onClose={() => setSnackbar({ ...snackbar, open: false })}
              style={{ position: 'fixed', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}
            >
              <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }} variant="filled" style={{ width: '300px', color: "white", height: '45px', fontSize: '12px', alignItems: 'center' }}>
                {snackbar.message}
              </Alert>
            </Snackbar>
            {errorMessage && <div style={{ fontSize: '20px', color: 'red' }}>{errorMessage}</div>}
            {/* {hierarchyData?.info && <InfoTooltip info={hierarchyData.info} />} */}
            <div style={{ fontSize: '50px' }}   >
              <InfoTooltip info="The following roles are default and cannot be removed from the system" />
            </div>
            {/* {JSON.stringify(hierarchyData)} */}
            <div style={{overflow:"auto",maxWidth:"100%",padding:"10px",boxSizing:"border-box"}}>
            <Tree>
              {hierarchyData && (
                <HierarchyNode
                  node={hierarchyData}
                  handleAddSiblingNode={handleAddSiblingNode}
                  handleEditNode={handleEditNode}
                  handleAddNode={handleAddNode}
                  handleDeleteNode={handleDeleteNode}
                  deletedNodes={[]}
                  isParentDeleted={false}
                  isDeleted={hierarchyData.is_deleted}
                />
              )}
            </Tree>
          </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default Roles;