import React,{ useEffect, useState } from "react";
import { routers, tabs } from "../../configuration/configurationUI";
import "../../configuration/configurationUI";
import { commonActions } from "../../utils/commonstore";
import store from "../../utils/index";
import { useSelector,useDispatch } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import MyNavItem from "./MyNaviItem";


 
const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});

export default function MyNavigation(props) {
  const dispatch = useDispatch();
  const accessInfo = useSelector(commonSelectors.selectAccessInfo);
  // console.log("accessInfostore",accessInfo)
  const [opened, setOpened] = React.useState([]);

  React.useEffect(() => {
    let open = [];
    for (let i = 0; i <= 21; i++) {
      open[i] = false;
    }
    setOpened(open);

  const storedAccessInfo = sessionStorage.getItem('accessInfo');
    if (storedAccessInfo) {
      const parsedAccessInfo = JSON.parse(storedAccessInfo);
      dispatch(commonActions.setAccessInfo(parsedAccessInfo));
    }
  }, [dispatch]);

  

  const setIndex = (index, stateOpen) => {
    let open = [];
    for (let i = 0; i <= 21; i++) {
      open[i] = false;
    }
    open[index] = stateOpen;
    setOpened(open);
  };
  const [activeItems, setActiveItems] = React.useState(Array(7).fill(false)); 


  const handleItemClick = (index) => {
    const newActiveItems = Array(7).fill(false);
    newActiveItems[index] = true; 
    setActiveItems(newActiveItems); 
  };
//   return (
//     <div className="page-sidebar sidebar"  >
//       <div className="menu accordion-menu" >
//       {/* {accessInfo?.dashboard?.charAt(0) === '1' && */}
//         <MyNavItem

//           title={tabs.dashboard[props.language]}
//           mainTab={routers.dashboard}
//           icon={"menu-icon glyphicon glyphicon-home"}
//           tabs={{}}
//           setOpenIndex={(stateOpen) => setIndex(0, stateOpen)}
//           open={opened[0]}
//           isSimpleNav={props.isSimpleNav}
//           onClick={() => handleItemClick(0)}
//           active={activeItems[0]}
//           activeclassname="active"
//         />
        
        
//         {/* <MyNavItem
//           title={tabs.Alerts[props.language]}
//           mainTab={routers.Alerts}
//           icon={"menu-icon glyphicon fa fa-bell"}
//           tabs={{}}
//           setOpenIndex={(stateOpen) => setIndex(1, stateOpen)}
//           open={opened[1]}
//           isSimpleNav={props.isSimpleNav}
//           onClick={() => handleItemClick(1)}
//           active={activeItems[1]}

//         /> */}
        
//         <MyNavItem
//           title={tabs.Forms[props.language]}
//           mainTab={routers.formslist}
//           icon={"menu-icon glyphicon glyphicon-edit"}
//           tabs={{}}
//           setOpenIndex={(stateOpen) => setIndex(2, stateOpen)}
//           open={opened[2]}
//           isSimpleNav={props.isSimpleNav}
//           onClick={() => handleItemClick(2)}
//           active={activeItems[2]}
//         />
//            {/* } */}
//         <MyNavItem
//           title={tabs.SubmitedFiles[props.language]}
//           mainTab={routers.SubmitedFiles}
//           icon={"menu-icon glyphicon glyphicon-file"}
//           tabs={{}}
//           setOpenIndex={(stateOpen) => setIndex(3, stateOpen)}
//           open={opened[3]}
//           isSimpleNav={props.isSimpleNav}
//           onClick={() => handleItemClick(3)}
//           active={activeItems[3]}

//         />
//         <MyNavItem 
//           title={tabs.UserManagement[props.language]}
//           mainTab={routers.UserManagement}

//           icon={"menu-icon glyphicon glyphicon-list"}

//           // icon ={"menu-icon glyphicon glyphicon-list"} 

//           tabs={{
//             [tabs.Roles[props.language]]: routers.Roles,
//             [tabs.Locations[props.language]]: routers.Locations,
//             [tabs.Users[props.language]]: routers.UsersList,
//             [tabs.Profile[props.language]]: routers.ProfileList,
//             [tabs.Departments[props.language]]: routers.Departments,
//             [tabs.PermissionsetList[props.language]]: routers.PermissionsetList,

//           }}
//           setOpenIndex={(stateOpen) => setIndex(4, stateOpen)}
//           open={opened[4]}
//           isSimpleNav={props.isSimpleNav}
//           onClick={() => handleItemClick(4)}
//           active={activeItems[4]}

//         />
//          {/* {accessInfo?.reports?.charAt(0) === '1' && */}
//         <MyNavItem
//           title={tabs.Reports[props.language]}
//           mainTab={routers.Reports}
//           icon={"menu-icon glyphicon glyphicon-stats"}
//           tabs={{}}
//           setOpenIndex={(stateOpen) => setIndex(5, stateOpen)}
//           open={opened[5]}
//           isSimpleNav={props.isSimpleNav}
//           onClick={() => handleItemClick(5)}
//           active={activeItems[5]}
//         />
//            {/* } */}
//          <MyNavItem
//           title={tabs.Audit[props.language]}
//           mainTab={routers.Audit}
//           icon={"icon-folder"}

//           tabs={{
//             [tabs.User[props.language]]: routers.UserAudit,
//             [tabs.Location[props.language]]: routers.LocationAudit,
//             [tabs.Department[props.language]]: routers.DepartmentAudit,
//             [tabs.Permissionset[props.language]]: routers.PermissionsetAudit,
//             [tabs.Incidents[props.language]]: routers.IncidentsAudit,

//           }}
//           setOpenIndex={(stateOpen) => setIndex(6, stateOpen)}
//           open={opened[6]}
//           isSimpleNav={props.isSimpleNav}
//           onClick={() => handleItemClick(6)}
//           active={activeItems[6]}
//         />
//       </div>
//     </div>
//   );
// }

const rl6User = JSON.parse(sessionStorage.getItem('rl6User'));
  const isVisitor = rl6User?.isVisitor;

  const reportTitle = typeof tabs.Reports[props.language] === 'string'
  ? tabs.Reports[props.language]
  : 'Default Report Title';


  const dynamicTabs = {
    ...(accessInfo?.roles?.charAt(0) === '1' && { [tabs.Roles[props.language]]: routers.Roles }),
    ...(accessInfo?.locations?.charAt(0) === '1' && { [tabs.Locations[props.language]]: routers.Locations }),
    ...(accessInfo?.users?.charAt(0) === '1' && { [tabs.Users[props.language]]: routers.UsersList }),
    ...(accessInfo?.profiles?.charAt(0) === '1' && { [tabs.Profile[props.language]]: routers.ProfileList }),
    ...(accessInfo?.departments?.charAt(0) === '1' && { [tabs.Departments[props.language]]: routers.Departments }),
    // ...(accessInfo?.permission_list?.charAt(0) === '1' && { [tabs.PermissionsetList[props.language]]: routers.PermissionsetList }),
};
const isUserManagementVisible = accessInfo && Object.keys(dynamicTabs).length > 0;
  return (
    <div className="page-sidebar sidebar">
      <div className="menu accordion-menu">
        {isVisitor ? (
          <MyNavItem
            title={tabs.Forms[props.language]}
            mainTab={routers.formslist}
            icon={"menu-icon glyphicon glyphicon-edit"}
            tabs={{}}
            setOpenIndex={(stateOpen) => setIndex(2, stateOpen)}
            open={opened[2]}
            isSimpleNav={props.isSimpleNav}
            onClick={() => handleItemClick(2)}
            active={activeItems[2]}
          />
        ) : (
          <>
            {accessInfo?.dashboard?.charAt(0) === '1' &&
            <MyNavItem
              title={tabs.dashboard[props.language]}
              mainTab={routers.dashboard}
              icon={"menu-icon glyphicon glyphicon-home"}
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(0, stateOpen)}
              open={opened[0]}
              isSimpleNav={props.isSimpleNav}
              onClick={() => handleItemClick(0)}
              active={activeItems[0]}
            />
            }
             {accessInfo?.forms?.charAt(0) === '1' &&
            <MyNavItem
            title={tabs.Forms[props.language]}
            mainTab={routers.formslist}
            icon={"menu-icon glyphicon glyphicon-edit"}
            tabs={{}}
            setOpenIndex={(stateOpen) => setIndex(2, stateOpen)}
            open={opened[2]}
            isSimpleNav={props.isSimpleNav}
            onClick={() => handleItemClick(2)}
            active={activeItems[2]}
          />
             }
              {accessInfo?.submitted_files?.charAt(0) === '1' &&
            <MyNavItem
              title={tabs.SubmitedFiles[props.language]}
              mainTab={routers.SubmitedFiles}
              icon={"menu-icon glyphicon glyphicon-file"}
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(3, stateOpen)}
              open={opened[3]}
              isSimpleNav={props.isSimpleNav}
              onClick={() => handleItemClick(3)}
              active={activeItems[3]}
            />
              }
             {isUserManagementVisible && (
            <MyNavItem
              title={tabs.UserManagement[props.language]}
              mainTab={routers.UserManagement}
              icon={"menu-icon glyphicon glyphicon-list"}
              tabs={dynamicTabs}
              setOpenIndex={(stateOpen) => setIndex(4, stateOpen)}
              open={opened[4]}
              isSimpleNav={props.isSimpleNav}
              onClick={() => handleItemClick(4)}
              active={activeItems[4]}
            />
          )} 
            {accessInfo?.reports?.charAt(0) === '1' &&
            <MyNavItem
              title={reportTitle}
              mainTab={routers.Reports}
              icon={"menu-icon glyphicon glyphicon-stats"}
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(5, stateOpen)}
              open={opened[5]}
              isSimpleNav={props.isSimpleNav}
              onClick={() => handleItemClick(5)}
              active={activeItems[5]}
            />
            }
            
             {accessInfo?.audit?.charAt(0) === '1' &&
            <MyNavItem
              title={tabs.Audit[props.language]}
              mainTab={routers.Audit}
              icon={"icon-folder"}
              tabs={{
                [tabs.User[props.language]]: routers.UserAudit,
                [tabs.Location[props.language]]: routers.LocationAudit,
                [tabs.Department[props.language]]: routers.DepartmentAudit,
                // [tabs.Permissionset[props.language]]: routers.PermissionsetAudit,
                [tabs.Incidents[props.language]]: routers.IncidentsAudit,
              }}
              setOpenIndex={(stateOpen) => setIndex(6, stateOpen)}
              open={opened[6]}
              isSimpleNav={props.isSimpleNav}
              onClick={() => handleItemClick(6)}
              active={activeItems[6]}
            />
            }
          </>
        )}
      </div>
    </div>
  );
}
