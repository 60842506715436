import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Modal, TextField, MenuItem } from "@mui/material";
import { apis } from "../../configuration/configurationAPI";
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import { Box } from '@mui/material';
import "react-datepicker/dist/react-datepicker.css";
import { FaEye, FaUserEdit, FaEdit, FaExchangeAlt, FaTrash, FaInfoCircle } from 'react-icons/fa';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import store from "../../utils/index";
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import UserExportExcel from "../../Components/UserExportButtons/UserExportExcel";
import UserExportPDF from "../../Components/UserExportButtons/UserExportPdf";
// import { DatePicker } from '@material-ui/pickers';
// import { DatePicker } from '@mui/lab';
import DatePicker from "react-datepicker";

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
    commonStore = store.getState().commonStore;
});


function UserList() {
    const navigate = useNavigate();
    const handleCreateUser = () => {
        navigate("/createuser")
    }
    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);
    const accessInfo = useSelector(commonSelectors.selectAccessInfo);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const [filterModel, setFilterModel] = useState({ items: [] });

    const [open, setOpen] = useState(false);
    const [permissions, setPermissions] = useState([]);
    // console.log("permissions:", permissions)
    const [selectedPermission, setSelectedPermission] = useState('');
    const [respectiveUserId, setRespectiveUserId] = useState(null);
    // console.log("respectiveUserId:",respectiveUserId)
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState();

    const handleOpen = (u_user_id) => {
        setRespectiveUserId(u_user_id); 
        setOpen(true); 
    };
    const handleClose = () => {
        setOpen(false);
        setSelectedPermission('');
        setFromDate(null);
        setToDate(null);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "auto",
        bgcolor: 'background.paper',
        border: '0px solid #000',
        boxShadow: 24,
        borderRadius: "10px",
        padding: "15px",
    };



    const containerStyle = {
        display: 'flex',
    };
    const colonStyle = {
        textAlign: 'center',
        width: '10px',
        fontWeight: 'bold',
    };
    const strongStyle = {
        display: 'inline-block',
        width: '150px',
        paddingRight: '10px',
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const formatDateToDDMMYYYY = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const paddedDay = day < 10 ? '0' + day : day;
        const paddedMonth = month < 10 ? '0' + month : month;
        return `${paddedDay}${paddedMonth}${year}`;
    };



    useEffect(() => {
        fetchUsersData();
    }, []);

    useEffect(() => {
        const handleFilterChange = debounce(() => {
            // console.log("Filter changed:", filterModel);
        }, 300);
        handleFilterChange();

        return () => {
            clearTimeout(handleFilterChange);
        };
    }, [filterModel]);

    const handleSubmit = () => {
        if (!selectedPermission || !fromDate || !toDate) {
            setError("Please select a permission set and both dates.");
            return;
        }


        const selectedPermissionObject = permissions.find(permission => permission.profile_name === selectedPermission);
        const permissionSetId = selectedPermissionObject ? selectedPermissionObject.profile_id : null;

        if (!permissionSetId) {
            setError("Invalid permission set selected.");
            return;
        }

        const payload = {
            assigned_to: respectiveUserId,
            is_deleted: false,
            valid_from: fromDate.toISOString(),
            valid_to: toDate.toISOString(),
        };

        axios.put(apis.base + apis.PUT_PROFILE_ADD_UPDATE_API + permissionSetId + '?updateType=put', payload, {
            headers: {
                userId: loginUserId,
                Authorization: "Bearer " + baererToken,
                profile_id: permissionSetId,
                updatedType: "put"
            }
        })
            .then((response) => {
                setError(null);
                handleClose();
                fetchUsersData();
            })
            .catch((error) => {
                console.log("Error while updating user permission:", error);
                setError("Error while updating user permission. Please try again.");
            });
    };


    useEffect(() => {
        fetchPermissions();
    }, []);

    const fetchPermissions = () => {
        axios.get(apis.base + apis.GET_PROFILE_PERMISSION_API,
            {
                headers: {
                    userid: loginUserId,
                    Authorization: "Bearer " + baererToken
                }
            })
            .then((succResp) => {
                const permissions = succResp.data.map((el) => {
                    return ({
                        ...el,
                        profile_id: el.profile_id,
                        profile_name: el.profile_name,
                        assigned_to_user: el.assigned_to_user,
                    })
                })
                setPermissions(permissions);
                setLoading(false);
            })
            .catch((errorresp) => {
                console.log(JSON.stringify(errorresp));
                setError("Error while fetching profile details. Please try again.");
                setLoading(false);
            });
    };



    const fetchUsersData = () => {
        axios.get(apis.base + apis.GET_USER_API, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken
            }

        })
            .then((succResp) => {

                const UsersWithIds = succResp.data.map((el, index) => {
                    return ({
                        ...el,
                        location_name: el.location_info.map((el) => (el.location_name)).join(", "),
                        id: index + 1

                    })
                })

                setRows(UsersWithIds);
                setLoading(false);
            })
            .catch((errorresp) => {
                console.log(JSON.stringify(errorresp));
                setError("Error while fetching User details. Please try again.");
                setLoading(false);
            });
    };

    const handleOpenModal = (userId) => {
        const user = rows.find(row => row.u_user_id === userId);
        setSelectedUser(user);
        setInfoModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedUserId(null);
        setSelectedUser(null);
        setInfoModalOpen(false);
    };

    const handleDeleteUser = (userId) => {
        // console.log("Deleting user with ID:", userId);
        setSelectedUserId(userId);
        setConfirmDialogOpen(true);
    };

    const handleConfirmRemoveUser = () => {
        // console.log("Confirmed removal for user ID:", selectedUserId);
        axios.delete(apis.base + apis.DELETE_USER_API + selectedUserId, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken
            }

        })
            .then((succResp) => {
                setConfirmDialogOpen(false);
                setError(null);
                fetchUsersData();
            })
            .catch((errorresp) => {
                console.log("Error while removing user:", errorresp);
                setConfirmDialogOpen(false);
                setError("Error while Removing User. Please try again.");
            });
    };

    const deleteAllowed = ["1001", "1011", "1101", "1111"];
    const editAllowed = ["1010", "1011", "1110", "1111"];
    const viewAllowed = ["1000", "1001", "1010", "1011", "1100", "1101", "1110", "1111"]
    const createAllowed = ["1100", "1101", "1110", "1111"]

    const canDelete = accessInfo?.users && deleteAllowed.includes(accessInfo?.users);
    const canEdit = accessInfo?.users && editAllowed.includes(accessInfo?.users);
    const canView = accessInfo?.users && viewAllowed.includes(accessInfo?.users);
    const canCreate = accessInfo?.users && createAllowed.includes(accessInfo?.users);

    const columns = [
        // { field: 'id', headerName: <strong>Id</strong>, width: 80 },
        { field: 'u_user_id', headerName: <strong>User Id</strong>, width: 120 },
        { field: 'u_user_name', headerName: <strong>User Name</strong>, width: 150 },
        { field: 'role_name', headerName: <strong>Role</strong>, width: 150 },
        { field: 'department_name', headerName: <strong>Department</strong>, width: 150 },
        // { field: 'profile_name', headerName: <strong>Profile Name</strong>, width: 150 },
        { field: 'location_name', headerName: <strong>Location</strong>, width: 200 },
        {
            field: 'action', headerName: <strong> Action</strong>, width: 150,
            renderCell: (params) => (
                <div>
                    {canDelete || canEdit || canView ? (
                        <div>
                            {canEdit && (
                                <Link to={`/edituser/${params.row.u_user_id}`} className="btn btn-normal btn-addon" title="Edit" style={{ paddingLeft: "0px" }}>
                                    <FaEdit style={{ color: 'black' }} />
                                </Link>
                            )}
                             {canDelete && (
                            <button
                                className="btn btn-normal btn-addon"
                                title="Delete"
                                style={{ background: 'none', border: 'none', padding: '0', cursor: 'pointer' }}
                                onClick={() => handleDeleteUser(params.row.u_user_id)}
                            >
                                <FaTrash style={{ color: 'black' }} />
                            </button>
                             )}
                              {canView && (
                            <button
                                className="btn btn-normal btn-addon"
                                title="Information"
                                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                                onClick={() => handleOpenModal(params.row.u_user_id)}
                            >
                                <FaEye style={{ color: 'black' }} />
                            </button>
                              )}
                        </div>
                    ) : null}
                </div>
            )

        },
        
        // {
        //     field: 'viewpermission',
        //     headerName: <strong>Permissions</strong>,
        //     width: 200,
        //     renderCell: (params) => (
        //         <div>
        //         {canCreate && (
        //         <Button
        //             variant="contained"
        //             style={{ backgroundColor: "#34425a" }}
        //             onClick={() => handleOpen(params.row.u_user_id)}
        //         >
        //             Add Permissions
        //         </Button>
        //         )}
        //         </div>
        //     )
        // }

    ];

    const addUser = ["1100", "1101", "1110", "1111"]

    return (
        <div>
            <div className="page-content">
                <div className="page-inner">
                    <div className="panel panel-white">
                        <div className="page-title">
                            <h3>UserList</h3>
                            <div className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li><Link to="/Dashboard">Home</Link></li>
                                    <li>UserList</li>
                                </ol>
                            </div>
                        </div>

                        <div id="main-wrapper">
                            <div className="row m-t-md">
                                <div className="col-md-12">
                                    <div className="mailbox-content" style={{ overflow: 'auto' }}>
                                        {!error && (
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 10px 5px" }}>
                                                <div>
                                                    {addUser.includes(accessInfo?.users) && (
                                                        <Button variant="contained" onClick={handleCreateUser}>Create User</Button>
                                                    )}
                                                </div>
                                                <div style={{ display: "flex", gap: "10px" }}>
                                                    {accessInfo?.exports === "1" && (
                                                        <>
                                                            <UserExportExcel />
                                                            <UserExportPDF />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {error && <p style={{ marginLeft: "30%", fontSize: "15px", color: "red" }}>{error}</p>}
                                        {loading && <p style={{ textAlign: "center", fontSize: "15px" }}>Loading...</p>}
                                        {!loading && !error && (
                                            <DataGrid
                                                rows={rows}
                                                columns={columns}
                                                pageSize={5}
                                                rowsPerPageOptions={[10, 20, 30]}
                                                loading={loading}
                                                classes={{
                                                    overlayWrapper: 'customOverlayWrapper'
                                                }}
                                                sx={{
                                                    "& .customOverlayWrapper ": {
                                                        height: "63px !important"
                                                    },
                                                    fontWeight: 'fontWeightLight',
                                                    mx: 0.5,
                                                    fontSize: 14, fontFamily: "sans-serif",
                                                    boxShadow: 2
                                                }}
                                                components={{
                                                    Toolbar: () => (
                                                        <GridToolbarQuickFilter
                                                            classes={{
                                                                toolbarQuickFilter: 'customtoolbarQuickFilter'
                                                            }}
                                                            sx={{
                                                                width: '35% !important',
                                                                position: 'absolute !important',
                                                                right: 300,
                                                                top: '-30px !important'
                                                            }}

                                                            filterModel={filterModel}
                                                            onFilterModelChange={(newModel) => setFilterModel(newModel)}
                                                        />
                                                    ),
                                                    NoRowsOverlay: () => (
                                                        <div
                                                            style={{
                                                                position: 'sticky',
                                                                top: '50%',
                                                                transform: 'translate(-50%, 0%)',
                                                                textAlign: 'center',
                                                                marginLeft: '45%'
                                                            }}
                                                        >
                                                            No data found
                                                        </div>
                                                    ),

                                                }}
                                            />

                                        )}
                                        {infoModalOpen && selectedUser && (
                                            <Modal
                                                open={infoModalOpen}
                                                onClose={handleCloseModal}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={style}>
                                                    <Typography
                                                        id="modal-modal-title"
                                                        variant="h6"
                                                        component="h2"
                                                    />
                                                    <FormControl>
                                                        <FormControl>
                                                            <RadioGroup
                                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                            />
                                                        </FormControl>
                                                    </FormControl>
                                                    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                                                        <div className="panel-body">
                                                            <div>
                                                                <div className="form-group">
                                                                    <p style={containerStyle}><strong style={strongStyle}>User ID</strong><span style={colonStyle}>:</span> {selectedUser.u_user_id}</p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <p style={containerStyle}><strong style={strongStyle}>Title</strong><span style={colonStyle}>:</span>{selectedUser.u_title} </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <p style={containerStyle}><strong style={strongStyle}>User Name</strong><span style={colonStyle}>:</span>{selectedUser.u_user_name}</p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <p style={containerStyle}><strong style={strongStyle}>Email ID</strong><span style={colonStyle}>:</span>{selectedUser.u_email} </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <p style={containerStyle}><strong style={strongStyle}>Department</strong><span style={colonStyle}>:</span>{selectedUser.department_name} </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <p style={containerStyle}><strong style={strongStyle}>Role</strong><span style={colonStyle}>:</span>{selectedUser.role_name} </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <p style={containerStyle}><strong style={strongStyle}>Profile</strong><span style={colonStyle}>:</span>
                                                                        {selectedUser.profile_name}
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <p style={containerStyle}><strong style={strongStyle}>Location</strong><span style={colonStyle}>:</span>{selectedUser.location_name} </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Typography>
                                                </Box>
                                            </Modal>
                                        )}

                                        <Modal
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                        >
                                            <div style={{ padding: '20px', background: '#fff', margin: '100px auto', width: '25%', textAlign: 'center', borderRadius: "5px" }}>
                                                <h2>Add Permission Set</h2>
                                                <TextField
                                                    select
                                                    label="Select Permission Set"
                                                    value={selectedPermission}
                                                    onChange={(e) => setSelectedPermission(e.target.value)}
                                                    fullWidth
                                                    margin="normal"
                                                >
                                                    {permissions.map(permission => (
                                                        <MenuItem key={permission.profile_id} value={permission.profile_name}>
                                                            {permission.profile_name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                <div style={{ display: "flex", gap: "5px", justifyContent: "center" }}>
                                                    <div>
                                                        <DatePicker
                                                            selected={fromDate}
                                                            onChange={(date) => setFromDate(date)}
                                                            dateFormat="MM/dd/yyyy"
                                                            showYearDropdown
                                                            scrollableYearDropdown
                                                            placeholderText="Select From Date"

                                                        />
                                                    </div>
                                                    <div>
                                                        <DatePicker
                                                            selected={toDate}
                                                            onChange={(date) => setToDate(date)}
                                                            dateFormat="MM/dd/yyyy"
                                                            showYearDropdown
                                                            scrollableYearDropdown
                                                            placeholderText="Select To Date"
                                                            style={{ width: '110px' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row" >
                                                    <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginTop: "10px", marginRight: "5px" }}>
                                                        Submit
                                                    </Button>
                                                    <Button variant="outlined" color="secondary" onClick={handleClose} style={{ marginTop: "10px" }}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ConfirmationDialog
                open={confirmDialogOpen}
                handleClose={() => setConfirmDialogOpen(false)}
                handleConfirm={handleConfirmRemoveUser}
            />

        </div>
    );
}

export default UserList;

function ConfirmationDialog({ open, handleClose, handleConfirm }) {
    // console.log("handleConfirm function:", handleConfirm);
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"

        >
            <DialogTitle id="alert-dialog-title" >Confirm Removal</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" >
                    Are you sure you want to remove this user?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button style={{ backgroundColor: "#34425a", color: "white" }} onClick={handleClose} >
                    Cancel
                </Button>
                <Button style={{ backgroundColor: "#22baa0", color: "white" }} onClick={handleConfirm} variant='contained' autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}