import React, { useEffect, useState } from "react";
import Checkbox from '@mui/material/Checkbox';
import { apis } from "../../configuration/configurationAPI";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Alert, MenuItem, Select, Snackbar,ClickAwayListener, TextField,Button } from "@mui/material";
import { Box } from "@mui/material";
import DatePicker from "react-datepicker";
import { Navigate, useNavigate,  } from "react-router-dom";
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import store from "../../utils/index";
const formatDate = (date) => {
    if (!(date instanceof Date)) {
        return "Invalid Date";
    }
    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
};
const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});
function EditPermissions() {
    const { profileId } = useParams();
    const today = new Date();
    const Navigate = useNavigate();
    const [permissionsetName, setPermissionsetName] = useState(formatDate(today));
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [permissions, setPermissions] = useState(null);
    const [editPermissions, setEditPermissions] = useState("");
    const [createdBy, setCreatedBy] = useState("");
    const [updatedBy, setUpdatedBy] = useState("");
    const [error, setError] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });
    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loggedinId = useSelector(commonSelectors.selectLoginUserId);
    const userInfo = useSelector(commonSelectors.selectUserInfo);
   
const loginUserId = useSelector(commonSelectors.selectLoginUserId);
    useEffect(() => {

        axios.get(apis.base + apis.GET_PROFILE_BY_ID_API + profileId,{
           headers: {
                    userId:loginUserId,
                    Authorization:"Bearer "+ baererToken
                }
        })

            .then((response) => {
                const { dashboard, audit, forms, submitted_files, roles, users,  profiles, departments, permission_list, locations,  reports, exports, profile_name, valid_from, valid_to, created_timestamp, updated_timestamp, assigned_to } = response.data;
                setPermissions({ dashboard,  audit, forms, submitted_files, roles, users, profiles, departments, permission_list, locations,  reports, exports });
                setError(null);
                setPermissionsetName(profile_name);
                // setFromDate(new Date(valid_from).toISOString().split('T')[0]);
                // setToDate(new Date(valid_to).toISOString().split('T')[0]);
                setCreatedBy(new Date(created_timestamp).toISOString().split('T')[0]);
                setUpdatedBy(new Date(updated_timestamp).toISOString().split('T')[0]);


            })
            .catch((error) => {
                console.error("Error fetching profile details:", error);
                setError("Error while fetching profile details. Please try again.");
            });

    }, [profileId]);

    const handleCheckboxChange = (key, index, isChecked) => {

        // console.log("previous edit", editPermissions);
        setEditPermissions((prevEditPermissions) => {
            const updatedPermissions = { ...prevEditPermissions };

            if (!updatedPermissions[key]) {
                updatedPermissions[key] = { ...permissions[key] };
            }

            updatedPermissions[key][index] = isChecked ? '1' : '0';

            // console.log(updatedPermissions);

            return updatedPermissions;
        });
        const isChanged = Object.values(editPermissions).some(obj =>
            Object.values(obj).some(value => value !== permissions[key][index])
        );
        setHasChanges(isChanged);
    };
    const flattenObjectValues = (obj) => {
        return Object.values(obj || {}).join('');
    };
    const handleSubmit = () => {
        const flattenedPermissions = {};

        for (const [key, value] of Object.entries(editPermissions)) {
            if (typeof value === 'object') {
                flattenedPermissions[key] = flattenObjectValues(value);
            } else {
                flattenedPermissions[key] = value;
            }
        }


        const payload = {

            profile_id: JSON.parse(profileId),
            profile_name: document.getElementById('permissionSetName')?.value,
            is_deleted: false,
            type: "profile",
            updated_by:loggedinId,
            
            ...flattenedPermissions,
        };
console.log("loginUserId",loginUserId)
        axios.put(apis.base + apis.PUT_PROFILE_ADD_UPDATE_API + profileId, payload, {
            headers: {
                userId:String(loginUserId),
                Authorization:"Bearer "+ baererToken
            },

            params: {
                updateType: 'put'
            },
        })
            .then((res) => {
                // console.log("before", res)

                setEditPermissions(res.data)

                // console.log("After", res.data)
                setSnackbar({
                    open: true,
                    message: 'Profile Edit Submitted successfully',
                    severity: 'success',
                });


                callback(res);
            })
            .catch((err) => {
                setSnackbar({
                    open: true,
                    message: 'Submission failed',
                    severity: 'success',
                });

            });
    };
    const callback = (res) => {

    };
   
    
    const handleSnackbarClose = () => {
        Navigate("/profilelist")
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <div>
            <div className="page-inner">
                <div className="page-title">
                    <h3 style={{ color: "black" }}>Edit Profile</h3>
                    <div className="page-breadcrumb">
                        <ol className="breadcrumb">
                        <li><Link to="/Dashboard">Home</Link></li>
                                <li><Link to="/profile">Profile</Link></li>
                            <li>Edit Profile</li>
                        </ol>
                    </div>
                </div>
                <div className="panel panel-white">
                <ClickAwayListener onClickAway={() => { }}>
                        <Snackbar
                            open={snackbar.open}
                            onClose={() => setSnackbar({ ...snackbar, open: false })}
                            style={{ position: 'fixed', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}
                        >
                            <Alert onClose={handleSnackbarClose} severity={snackbar.severity} style={{ width: '300px', height: '40px', fontSize: '12px' }}>
                                {snackbar.message}
                            </Alert>
                        </Snackbar>
                    </ClickAwayListener>
                    <div className="forms-sample">
                            <div className="row" style={{ marginLeft: "0px", marginRight: "0px", marginTop: "10px" }}>
                        <div className="col-md-2" > Profile Name 
                        <div >{permissionsetName}</div>
                        </div>


                        {/* <div className="col-md-2">
                                    <label htmlFor="toDate" className="form-label">From Date</label>
                                    <DatePicker
                                        id="toDate"
                                        className="form-control"
                                        selected={fromDate}
                                        isClearable
                                        placeholderText="MM/dd/yyyy"
                                        showYearDropdown
                                        scrollableYearDropdown
                                        onChange={(date) => setFromDate(date)}
                                        dateFormat="MM/dd/yyyy"
                                    />
                                </div> */}
                                {/* <div className="col-md-2">
                                    <label htmlFor="toDate" className="form-label">To Date</label>
                                    <DatePicker
                                        id="toDate"
                                        className="form-control"
                                        selected={toDate}
                                        isClearable
                                        placeholderText="MM/dd/yyyy"
                                        showYearDropdown
                                        scrollableYearDropdown
                                        onChange={(date) => setToDate(date)}
                                        dateFormat="MM/dd/yyyy"
                                    />
                                </div> */}
                       </div>

                    </div>
                    <div className="panel-body">
                        {error && <Box style={{ fontSize: '18px', color: "red", textAlign: 'center', }}>
                            {error}
                        </Box>
                        }
                        {!error && ( // Only render loading if there's no error
                            <div className="table-responsive">
                                {permissions === null ? (
                                    <p>Loading Profile...</p>
                                ) : permissions ? (
                                    <table id="example" className="display table" style={{ width: "100%" }}>
                                        <thead style={{backgroundColor:"rgb(35 212 182)"}}>
                                            <tr>
                                                <th> </th>
                                                <th>VIEW</th>
                                                <th>CREATE</th>
                                                <th>EDIT</th>
                                                <th>DELETE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(permissions).map(([key, permissions]) => (
                                                <tr key={key}>
                                                    <td>{key.toUpperCase()}</td>
                                                    {typeof permissions === "string" ? (
                                                        permissions.split("").map((value, index) => {
                                                            if (!editPermissions[key] || (editPermissions[key][index] == undefined))
                                                                (value == "1") && handleCheckboxChange(key, index, value)
                                                            return (
                                                                <td key={index}>
                                                                    <Checkbox
                                                                        checked={(editPermissions[key] && editPermissions[key][index] === "1")}
                                                                        onChange={(e) => handleCheckboxChange(key, index, e.target.checked)}
                                                                        style={{
                                                                            color: value == "1" ? "blue" : "inherit",

                                                                        }}
                                                                    />
                                                                </td>
                                                            )
                                                        })
                                                    ) : (
                                                        <td colSpan="5">Invalid Profile data</td>

                                                    )}
                                                </tr>
                                            ))}


                                        </tbody>
                                    </table>
                                ) : (
                                    <p>No permissions found</p>
                                )}

                            </div>
                        )}
                       <div className="panel-body" style={{ display: 'flex' }}>
                            <div>
                            <Button variant="outlined" style={{ backgroundColor: "#22baa0",color:"white" }} onClick={handleSubmit} >Submit</Button>
                        
                        </div>
                        <div>
                            <Link to="/permissionsetlist">
                                <Button type="button" variant="contained" className="btn btn-success" style={{ marginLeft: '30px', backgroundColor: "#34425a" }} >Cancel</Button>
                            </Link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditPermissions;